export const daysDifference = 10
// export const daysOf_1h_timeBucket = 3
// export const daysOf_15m_timeBucket = 1
// export const daysOf_4h_timeBucket = 6
// export const daysOf_1d_timeBucket = 15
// export const daysOf_1m_timeBucket = 1
// export const daysOf_5m_timeBucket = 1
export const startOfUnixTimeStamp = 13457

export const daysOf_1h_timeBucket = 1
export const daysOf_15m_timeBucket = 17
export const daysOf_4h_timeBucket = 2
export const daysOf_1d_timeBucket = 15
export const daysOf_1m_timeBucket = 10
export const daysOf_5m_timeBucket = 12

export const USDT_contractAddress = '0x55d398326f99059ff775485246999027b3197955'
export const USDC_contractAddress = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
export const USDT_eth_contractAddress = '0xdac17f958d2ee523a2206206994597c13d831ec7'
export const BUSD_contractAddress = '0xe9e7cea3dedca5984780bafc599bd69add087d56'

export const pancakeSwap = 'pancakeswap-v2'
export const apeSwap = 'apeswap'
export const babySwap = 'babyswap'
export const fstSwap = 'fstswap'
export const nomiSwap = 'nomiswap'
export const biSwap = 'biswap'

export const uniswap = 'uniswap-v2'
export const sushi = 'sushi'
export const shibaSwap = 'shiba-swap'
