import React, {useState, useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {palette} from '../Colors'
import {AiOutlineMenuUnfold} from 'react-icons/ai'
import {BiWallet} from 'react-icons/bi'
import {SiLeaflet} from 'react-icons/si'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {BsSunFill, BsMoonFill} from 'react-icons/bs'
import {media} from 'styled-bootstrap-grid'
import AppContext from '../api/AppContext'
import useWindowSize from '../hooks/useWindowSize'
const v = {
	sidebarWidth: `300px`,
	smSpacing: `8px`,
	mdSpacing: `16px`,
	lgSpacing: `24px`,
	xlSpacing: `32px`,
	xxlSpacing: `48px`,
	borderRadius: `6px`
}

const Sidebar = ({setTrendingCoin}) => {
	const context = useContext(AppContext)
	const {isDarkTheme, setIsDarkTheme, isSideMenuOpen, setIsSideMenuOpen} = context
	const {pathname} = useLocation()
	const {width} = useWindowSize()
	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [dropDownOpen, setDropDownOpen] = useState(false)

	useEffect(() => {
		if (isSideMenuOpen) {
			setSidebarOpen(true)
		} else if (isSideMenuOpen == false) {
			setSidebarOpen(false)
		}
	}, [isSideMenuOpen])

	const sideBarCheck = () => {
		if (width <= 992) {
			setIsSideMenuOpen(false)
		}
	}
	return (
		<SideNavBar isOpen={sidebarOpen}>
			<SSidebar isOpen={sidebarOpen} isDarkTheme={isDarkTheme}>
				<span>
					<SSidebarButton
						isDarkTheme={isDarkTheme}
						isOpen={sidebarOpen}
						onClick={() => {
							if (width > 992) {
								setSidebarOpen((p) => !p)
							}
						}}>
						<SLinkLabel sidebarOpen={sidebarOpen}>Chains</SLinkLabel>
						{width > 992 && <AiOutlineMenuUnfold />}
					</SSidebarButton>
					<SDivider />
					<SLinkContainer
						isDarkTheme={isDarkTheme}
						isOpen={sidebarOpen}
						isActive={pathname === '/multi-charts'}
						onClick={() => {
							if (sidebarOpen) {
								setDropDownOpen(false)
							} else {
								setSidebarOpen(true)
								setIsSideMenuOpen(true)
							}
						}}>
						<SLink to={'/multi-charts'} style={!sidebarOpen ? {width: `fit-content`} : {}} onClick={sideBarCheck}>
							<SLinkIcon>
								<BiWallet />
							</SLinkIcon>
							<SLinkLabel sidebarOpen={sidebarOpen}>Multi Chart</SLinkLabel>
						</SLink>
					</SLinkContainer>

					<SLinkContainer
						isDarkTheme={isDarkTheme}
						isOpen={sidebarOpen}
						isActive={pathname === '/wallet'}
						onClick={() => {
							if (sidebarOpen) {
								setDropDownOpen(false)
							} else {
								setSidebarOpen(true)
								setIsSideMenuOpen(true)
							}
						}}>
						<SLink to={'/wallet'} style={!sidebarOpen ? {width: `fit-content`} : {}} onClick={sideBarCheck}>
							<SLinkIcon>
								<BiWallet />
							</SLinkIcon>
							<SLinkLabel sidebarOpen={sidebarOpen}>Wallet</SLinkLabel>
						</SLink>
					</SLinkContainer>
					<SLinkContainer
						isDarkTheme={isDarkTheme}
						isOpen={sidebarOpen}
						isActive={pathname === '/new-pairs'}
						onClick={() => {
							if (sidebarOpen) {
								setDropDownOpen(false)
							} else {
								setSidebarOpen(true)
								setIsSideMenuOpen(true)
							}
						}}>
						<SLink to={'/new-pairs'} style={!sidebarOpen ? {width: `fit-content`} : {}} onClick={sideBarCheck}>
							<SLinkIcon>
								<LeafIcon />
							</SLinkIcon>
							<SLinkLabel sidebarOpen={sidebarOpen}>New Pairs</SLinkLabel>
						</SLink>
					</SLinkContainer>
					<SLinkContainer
						isDarkTheme={isDarkTheme}
						isOpen={sidebarOpen}
						isActive={pathname === '/ethereum' || pathname.includes('/ethereum')}
						onClick={() => {
							setTrendingCoin('ethereum')
							if (sidebarOpen) {
								setDropDownOpen(false)
							} else {
								setSidebarOpen(true)
								setIsSideMenuOpen(true)
							}
						}}>
						<SLink to={'/ethereum'} style={!sidebarOpen ? {width: `fit-content`} : {}} onClick={sideBarCheck}>
							<SLinkIcon>
								<Icons src="/images/ethereum.png" alt="ethereum" />
							</SLinkIcon>

							<SLinkLabel sidebarOpen={sidebarOpen}>Ethereum Mainnet</SLinkLabel>
						</SLink>
					</SLinkContainer>
					<SLinkContainer
						isDarkTheme={isDarkTheme}
						isOpen={sidebarOpen}
						isActive={pathname === '/' || pathname.includes('/binance')}
						onClick={() => {
							setTrendingCoin('binance')
							if (sidebarOpen) {
								setDropDownOpen(false)
							} else {
								setSidebarOpen(true)
								setIsSideMenuOpen(true)
							}
						}}>
						<SLink to={'/'} style={!sidebarOpen ? {width: `fit-content`} : {}} onClick={sideBarCheck}>
							<SLinkIcon>
								<Icons src="/images/binance.png" alt="binance" />
							</SLinkIcon>
							<SLinkLabel sidebarOpen={sidebarOpen}>BNB Chain</SLinkLabel>
						</SLink>
					</SLinkContainer>
					<SDivider />
					<DescriptionContent>
						<SocialLink href="https://discord.gg/NfUCZSpU" target="_blank" rel="noopener noreferrer">
							<SocialIcons isDarkTheme={isDarkTheme} src="/images/Discord.png" alt="Discord" />
							{sidebarOpen && <SocialLabel>Discord</SocialLabel>}
						</SocialLink>
						<SocialLink href="https://www.economicsale.finance/" target="_blank" rel="noopener noreferrer">
							<SocialIcons isDarkTheme={isDarkTheme} src="/images/Telegram.png" alt="Telegram" />
							{sidebarOpen && <SocialLabel>Telegram</SocialLabel>}
						</SocialLink>
						<SocialLink href="https://twitter.com/economic_sale" target="_blank" rel="noopener noreferrer">
							<SocialIcons isDarkTheme={isDarkTheme} src="/images/Twitter.png" alt="Twitter" />
							{sidebarOpen && <SocialLabel>Twitter</SocialLabel>}
						</SocialLink>
					</DescriptionContent>

					{/* <SLinkDropMenu isOpen={sidebarOpen}>
                        <SDrop
                            style={!sidebarOpen ? { width: `fit-content` } : {}}
                            dropOpen={dropOne}
                            onClick={() => {
                                if (sidebarOpen) {
                                    setDropOne(!dropOne)
                                } else {
                                    setSidebarOpen(true)
                                }
                            }}
                            isActive={pathname === '/create_presale' || pathname === '/view_presales'}>
                            <SLinkIcon>
                                <AiOutlineRocket />
                            </SLinkIcon>
                            {sidebarOpen && (
                                <>
                                    <SLinkLabel>Presale</SLinkLabel>
                                    <SLinkNotification> {dropOne ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</SLinkNotification>
                                </>
                            )}
                        </SDrop>
                        {sidebarOpen && (
                            <DropMenu sidebarOpen={sidebarOpen}>
                                <DropMenuList to={'/create_presale'} isActive={pathname === '/create_presale'}>
                                    Create Presale
                                </DropMenuList>
                                <DropMenuList to={'/view_presales'} isActive={pathname === '/view_presales'}>
                                    Presale List
                                </DropMenuList>
                            </DropMenu>
                        )}
                    </SLinkDropMenu> */}
				</span>
				<Cover
					isDarkTheme={isDarkTheme}
					isActive={!isDarkTheme}
					onClick={() => {
						if (isDarkTheme) {
							setIsDarkTheme(false)
						} else {
							setIsDarkTheme(true)
						}
					}}>
					{isDarkTheme ? <BsSunFill /> : <BsMoonFill />}
				</Cover>
			</SSidebar>
		</SideNavBar>
	)
}

const SideNavBar = styled.div`
	width: ${({isOpen}) => (!isOpen ? `4rem` : '15.625rem')};
	position: relative;
	transition: width 70ms ease-in-out 0s;
	z-index: 4;
	${media.lg`width: ${({isOpen}) => (!isOpen ? `4rem` : '17.5rem')};`};
	${media.xl`width: ${({isOpen}) => (!isOpen ? `4rem` : '16.5rem')};`};
	@media screen and (min-width: 0px) and (max-width: 575px) {
		position: absolute;
		display: ${({isOpen}) => (isOpen ? `block` : 'none')};
		right: -2.8rem;
		top: 8.2rem;
	}
	@media screen and (min-width: 575px) and (max-width: 992px) {
		position: absolute;
		display: ${({isOpen}) => (isOpen ? `block` : 'none')};
		right: -1rem;
	}
`

const SSidebar = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_mode_shadow}` : `${palette.light_mode_shadow}`)};
	width: ${({isOpen}) => (!isOpen ? `4rem` : '13.75rem')};
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	// height: calc(100vh - 80px);
	padding: 0.75rem;
	position: fixed;
	z-index: 4;
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(100vh - 10rem);
	transition: width 70ms ease-in-out 0s;
	${media.xs`
	height: calc(100vh - 12.7rem);
	`};
	@media screen and (min-width: 0px) and (max-width: 670px) {
		width: ${({isOpen}) => (!isOpen ? `4rem` : '12.75rem')};
	}

	/* width */
	::-webkit-scrollbar {
		width: 3px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	}
`

const SSidebarButton = styled.div`
	padding: 0;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black2}` : `${palette.blue_light}`)};
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.blue}`)};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transform: ${({isOpen}) => (!isOpen ? `rotate(180deg)` : `initial`)};
	cursor: pointer;
	margin: 8px 0;
`

const SDivider = styled.div`
	height: 1px;
	width: 100%;
	background: ${palette.charcol_light};
	margin: 8px 0 11px 0;
`

const SLinkContainer = styled.div`
	position: relative;
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.blue_gradient}` : `${palette.blue_light}`) : `transparent `)};
	color: ${({isDarkTheme, isActive}) => (isDarkTheme ? `${palette.white}` : isActive ? `${palette.blue}` : `${palette.off_dark}`)};
	font-weight: ${({isActive}) => (isActive ? 'bold' : 'normal')};
	border-radius: ${v.borderRadius};
	margin: 8px 0;
	display: ${({isOpen}) => (isOpen ? 'block' : 'flex')};
	justify-content: ${({isOpen}) => (isOpen ? 'start' : 'center')};
	& > a:hover {
		color: ${({isDarkTheme, isActive}) => (isDarkTheme ? (isActive ? `${palette.white}` : `${palette.aqua_blue}`) : isActive ? `${palette.blue}` : `${palette.blue}`)} !important;
	}
`

const SLink = styled(Link)`
	display: flex;
	align-items: center;
	text-decoration: none;
	color: inherit;
	font-size: 16px;
	padding: 0 0;
`

const SLinkIcon = styled.div`
	padding: 0.5rem;
	display: flex;
	svg {
		font-size: 1.2rem;
	}
`

const SLinkLabel = styled.span`
	display: block;
	flex: 1;
	font-size: 0.9rem;
	margin-left: ${v.smSpacing};
	display: ${({sidebarOpen}) => (!sidebarOpen ? `none` : 'block')};
`

const Cover = styled.div`
	margin-left: 0.7rem;
	border-radius: 0.2rem;
	color: ${({isActive}) => (isActive ? `${palette.black}` : `${palette.yellow}`)};
	cursor: pointer;
`
const Icons = styled.img`
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 3rem;
`

const LeafIcon = styled(SiLeaflet)`
	color: ${palette.green};
`

const DescriptionContent = styled.div`
	display: flex;
	flex-direction: column;
	& a {
		margin-left: 0.5rem;
	}
`

const SocialLink = styled.a`
	cursor: pointer;
	margin: 8px 0;
	text-decoration: none !important;
	display: flex;
	&:hover > img,
	&:hover > p {
		opacity: 0.7;
	}
`

const SocialIcons = styled.img`
	cursor: pointer;
	width: 1.3rem;
	height: 1.3rem;
`
const SocialLabel = styled.p`
	cursor: pointer;
	margin-left: 0.8rem;
	font-size: 0.9rem;
	color: ${palette.aqua_blue};
`

export default Sidebar
