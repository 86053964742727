import React, {useState, useRef, useEffect, useContext} from 'react'
import styled from 'styled-components'
import AppContext from '../api/AppContext'
import moment from 'moment'
import {palette} from '../Colors'
import axios from 'axios'
import {AiOutlineSearch} from 'react-icons/ai'
import {useLocation} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const SearchBar = ({setTrendingCoin}) => {
	const navigate = useNavigate()
	const [options, setOptions] = useState([])
	const [inputField, setInputField] = useState('')
	const [loading, setLoading] = useState(false)
	const context = useContext(AppContext)
	const {isDarkTheme, scrollPosition, setRequiredPair, setShowGraph, setChartStartDate, setGraphLoading, checkReloadState} = context
	const ulRef = useRef()
	const inputRef = useRef()
	const [showUl, setShowUl] = useState(false)
	let count = useRef(0)
	const location = useLocation()
	let base_id = location.pathname.split('/', 3)[2]

	useEffect(() => {
		if (checkReloadState === true && base_id) {
			setInputField(base_id)
			searchHandler(base_id)
		}
		if (count.current === 0) {
			count.current = count.current + 1
		}
	}, [])

	useEffect(() => {
		setChartStartDate(moment().subtract(2, 'day').utc().format('YYYY-MM-DD HH:mm:ss'))
		inputRef.current.addEventListener('click', (event) => {
			event.stopPropagation()
			if (options !== []) {
				ulRef.current.style.display = 'flex'
			}
		})
		document.addEventListener('click', (event) => {
			ulRef.current.style.display = 'none'
			setOptions([])
			setShowUl(false)
			setInputField('')
		})
	}, [])

	const searchHandler = async (val) => {
		try {
			setShowUl(true)
			setLoading(true)
			const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/contractAddress`, {
				contractAddress: val
			})

			const data = res.data
			console.log("I'm hit", data)
			setLoading(false)

			setOptions(data)
		} catch (error) {
			console.log(error)
		}
	}

	const handleSearchOnEnter = (e) => {
		if (e.key === 'Enter') {
			searchHandler(inputField)
		}
	}

	return (
		<Wrapper>
			<Search
				placeholder="Search by token name, symbol or address"
				isDarkTheme={isDarkTheme}
				scroll={scrollPosition}
				ref={inputRef}
				value={inputField}
				onKeyDown={handleSearchOnEnter}
				onChange={(e) => {
					setInputField(e.target.value)
				}}
			/>

			<IconCover isDarkTheme={isDarkTheme} scroll={scrollPosition}>
				<SearchIcon />
			</IconCover>

			{loading ? (
				<Ul ref={ulRef} active={options !== []}>
					<DropDownList isDarkTheme={isDarkTheme}>
						<LoadingPanelContent isDarkTheme={isDarkTheme}>
							<LoadingPanel src="/images/Preloader.gif" />
						</LoadingPanelContent>
					</DropDownList>
				</Ul>
			) : (
				showUl && (
					<Ul ref={ulRef} active={options !== []} isDarkTheme={isDarkTheme}>
						{options?.results?.map((option, index) => {
							return (
								<DropDownList
									isDarkTheme={isDarkTheme}
									key={index}
									onClick={() => {
										setRequiredPair(option)
										// sendRawData(option)
										setGraphLoading(true)
										setShowGraph(false)

										setTimeout(() => {
											//option.chain_slug === 'ethereum' ? navigate(`/ethereum/${option.base_token_address}`) : navigate(`/binance/${option.base_token_address}`)
											if (option.chain_slug === 'ethereum') {
												navigate(`/ethereum/${option.base_token_address}`)
												setTrendingCoin('ethereum')
											} else {
												navigate(`/binance/${option.base_token_address}`)
												setTrendingCoin('binance')
											}
											setGraphLoading(false)
											setShowGraph(true)
											setInputField('')
										}, 1000)
									}}>
									<Card>
										<ImgCover>
											{option.chain_slug.toLowerCase() === 'ethereum' ? <Img src="/images/ethereum.png" /> : option.chain_slug.toLowerCase() === 'binance' ? <Img src="/images/binance.png" /> : <Img src="/images/polygon.png" />}
										</ImgCover>
										<Details>
											<Flexed>
												<Text isDarkTheme={isDarkTheme}>{option.base_token_symbol}&nbsp;/</Text>
												<Text isDarkTheme={isDarkTheme} margin={'6px 0 6px 0'} color={`${palette.grayy}`}>
													{option.quote_token_symbol}
												</Text>
												<Text isDarkTheme={isDarkTheme} padding={'0 0 0 10px'}>
													{option.pair_name}
												</Text>
											</Flexed>

											<Flexed>
												<Text isDarkTheme={isDarkTheme}>${option.usd_price_latest}</Text>
												<Flexed padding={'0 0 0 15px'}>
													<Text isDarkTheme={isDarkTheme}>24H Change:</Text>

													<Text isDarkTheme={isDarkTheme} color={`${palette.chart_green}`}>
														{' '}
														{option.price_change_24h?.toFixed(2)}
													</Text>
												</Flexed>

												<Flexed padding={'0 0 0 15px'}>
													<Text isDarkTheme={isDarkTheme}>Liquidity: </Text>
													<Text isDarkTheme={isDarkTheme} fontWeight={700}>
														${option.usd_liquidity_latest?.toFixed(2)}
													</Text>
												</Flexed>
											</Flexed>

											<Flexed>
												<Text isDarkTheme={isDarkTheme} color={`${palette.grayy}`}>
													Pair: {option.pool_address.substr(0, 5)}...{option.pool_address.substr(38, 41)}
												</Text>
												<Text isDarkTheme={isDarkTheme} color={`${palette.grayy}`}>
													{option.base_token_symbol}: {option.base_token_address.substr(0, 5)}...{option.base_token_address.substr(38, 41)}
												</Text>
											</Flexed>
										</Details>
									</Card>
								</DropDownList>
							)
						})}

						{options.error_id || options.error ? (
							<DropDownList isDarkTheme={isDarkTheme}>
								<Card justifyContent="center">
									<NotFound>Record Not Found</NotFound>
								</Card>
							</DropDownList>
						) : null}
					</Ul>
				)
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: relative;
	padding: 0px;
	margin-top: 0.36rem;
`
const Search = styled.input`
	position: relative;
	outline: none;
	all: unset;
	width: 100%;
	height: 39px;
	padding-left: 5px;
	padding-right: 2.5rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.off_dark}`)};
	border: 0.09rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
	border-radius: 0.3rem;
	box-sizing: border-box;
	::placeholder {
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		opacity: 1; /* Firefox */
		font-size: 0.9rem;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		font-size: 0.9rem;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		font-size: 0.9rem;
	}
`

const NotFound = styled.p`
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.charcol}`)};
	padding: 20px 10px;
`

const DropDownList = styled.div`
	all: unset;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	width: 100%;
	padding: 5px 0px 5px 0px;
	z-index: 999;
	margin: 0px;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	&:not(:first-child) {
		border-top: ${({key}) => (key === 0 ? ` null` : `1px solid ${palette.charcol_light}`)};
	}
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	}
	cursor: pointer;
`

const Ul = styled.ul`
	display: flex;
	z-index: 999;
	flex-direction: column;
	width: 100%;
	padding: 0px !important;
	margin-top: 0px !important;
	max-height: 450px;
	overflow-y: auto;
	overflow-x: hidden;
	scrollbar-color: red yellow;
	// border-left: ${({active}) => (active ? `2px solid ${palette.charcol_light}` : null)};
	// border-right: ${({active}) => (active ? `2px solid ${palette.charcol_light}` : null)};
	// border-bottom: ${({active}) => (active ? `2px solid ${palette.charcol_light}` : null)};
	box-shadow: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_mode_shadow}` : `${palette.light_mode_shadow}`)};
	border-bottom-right-radius: ${({active}) => (active ? '4px' : '0px')};
	border-bottom-left-radius: ${({active}) => (active ? '4px' : '0px')};
`

const Card = styled.div`
	display: flex;
	justify-content: ${({justifyContent}) => `${justifyContent}`};
	z-index: 999;
`
const Details = styled.div``

const Flexed = styled.div`
	display: flex;
	padding: ${({padding}) => padding};
`

const Text = styled.p`
	padding: ${({padding}) => padding};
	margin: ${({margin}) => margin || '6px'};
	color: ${({color, isDarkTheme}) => (color ? color : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	background-color: ${({bgColor}) => bgColor};
	font-weight: ${({fontWeight}) => fontWeight};
	display: flex;
`

const ImgCover = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px;
`

const Img = styled.img`
	width: 24px;
	height: 24px;
	border-radius: 50%;
`

const LoadingPanelContent = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	background: ${palette.overlay};
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.overlay}` : `${palette.white}`)};
`

const LoadingPanel = styled.img`
	width: 20%;

	filter: ${({isDarkTheme}) => (isDarkTheme ? `invert(61%) sepia(26%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)` : `invert(61%) sepia(2%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)`)};
`

const SearchIcon = styled(AiOutlineSearch)`
	color: ${palette.charcol_light};
	font-size: 1.2em;
`
const IconCover = styled.div`
	position: absolute;
	top: 1px;
	right: 1px;
	height: 2.3rem;
	display: flex;
	align-items: center;
	padding: 0 0.5rem 0 0.5rem;
	border-radius: 4px;
`
export default SearchBar
