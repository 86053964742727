import React from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {palette} from '../Colors'
// import { useSelector } from 'react-redux'

const Footer = () => {
	const isDarkTheme = true
	return (
		<>
			<Wrapper id="faraz">
				<Row justifyContent="center">
					<CustomCol>
						<Text isDarkTheme={isDarkTheme} href="https://www.economicsale.finance/" target="_blank">
							Privacy Policy
						</Text>
						<Text isDarkTheme={isDarkTheme} href="https://www.economicsale.finance/" target="_blank">
							Contact Us
						</Text>
						<Text isDarkTheme={isDarkTheme} href="https://www.economicsale.finance/" target="_blank">
							Terms & Conditions
						</Text>
					</CustomCol>
				</Row>
			</Wrapper>
		</>
	)
}

const Wrapper = styled(Container)`
	margin-top: auto;
	border-top: 0.1rem solid ${palette.gray_light};
	padding-top: 1rem;
	padding-bottom: 1rem;
	max-width: 100%;
`

const CustomCol = styled(Col)`
	display: flex;
	justify-content: center;
	@media screen and (min-width: 200px) and (max-width: 450px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	} ;
`

const Text = styled.a`
	font-family: 'bicubik', sans-serif;
	letter-spacing: 0.1em;
	cursor: pointer;
	font-size: 0.8rem;
	display: inline-block;
	&:not(:last-child) {
		margin-right: 1rem;
	}
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	text-decoration: none;
	&:hover {
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
		opacity: 0.7;
	}
`
export default Footer
