import React, {useState, useContext, useEffect} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import styled, {keyframes} from 'styled-components'
import SearchBar from './SearchBar'
import {AiOutlineLoading3Quarters} from 'react-icons/ai'
import {palette} from '../Colors'
import {handleBackgroundType, handleBorderType} from './Button'
import {useNavigate} from 'react-router-dom'
import AppContext from '../api/AppContext'
import {BsXLg} from 'react-icons/bs'
import {AiOutlineMenuUnfold} from 'react-icons/ai'

const NavBar = ({setTrendingCoin}) => {
	let connectedNetwork = ''
	const context = useContext(AppContext)
	const {isDarkTheme, scrollPosition, setScrollPosition, isSideMenuOpen, setIsSideMenuOpen} = context
	let navigate = useNavigate()
	const [buttonLoader, setButtonLoader] = useState(false)
	const handleConnect = async () => {}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, {passive: true})
	}, [])

	const handleScroll = () => {
		const _position = window.pageYOffset
		setScrollPosition(_position)
	}
	return (
		<Main fluid isDarkTheme={isDarkTheme} scroll={scrollPosition}>
			<CustomRow>
				<Col lg={4} md={2} sm={2} xs={6}>
					<LogoContent
						onClick={() => {
							navigate('/')
						}}>
						<Logo src="/es_logo.png" />
						<LogoText>
							<BrandName isDarkTheme={isDarkTheme}>
								Economic&nbsp;<span>Sale</span>
							</BrandName>
							<TagLine isDarkTheme={isDarkTheme}>
								Defi <span>Launchpad</span>
							</TagLine>
						</LogoText>
					</LogoContent>
				</Col>
				<Col lg={6} md={7} sm={6} xs={12} xsOrder={3}>
					<SearchBar setTrendingCoin={setTrendingCoin} />
				</Col>
				<Col lg={2} md={3} sm={4} xs={6} xsOrder={2}>
					<FlexRight>
						<DropMenu isConnected={connectedNetwork}>
							<ButtonWrapper>
								<ConnectButton connectedNetwork={connectedNetwork} isDarkTheme={isDarkTheme} disabled={buttonLoader == true ? true : false} onClick={() => (!connectedNetwork ? handleConnect() : '')}>
									{buttonLoader == false ? (
										!connectedNetwork ? (
											'Connect'
										) : (
											<>{connectedNetwork.title}</>
										)
									) : (
										<span>
											<Processing /> &nbsp;Connecting...
										</span>
									)}
								</ConnectButton>
							</ButtonWrapper>
						</DropMenu>
						{!isSideMenuOpen ? (
							<OpenSideNav
								isDarkTheme={isDarkTheme}
								onClick={() => {
									setIsSideMenuOpen(true)
								}}>
								<AiOutlineMenuUnfold />
							</OpenSideNav>
						) : (
							<CloseSideNav
								isDarkTheme={isDarkTheme}
								onClick={() => {
									setIsSideMenuOpen(false)
								}}>
								<BsXLg />
							</CloseSideNav>
						)}
					</FlexRight>
				</Col>
			</CustomRow>
		</Main>
	)
}

const Main = styled(Container)`
	position: fixed;
	z-index: 4;
	background: ${({scroll, isDarkTheme}) => (isDarkTheme ? (scroll == 0 ? 'rgba(4, 6, 9, 1)' : `${palette.transparent_black}`) : `${palette.transparent_white}`)};
`

const CustomRow = styled(Row)`
	// background-color: ${palette.black};
	// box-shadow: 0px 3px 15px rgba(255, 255, 255, 0.2);
	border-bottom: 1px solid ${palette.charcol_light};
	padding: 1rem 0rem;
	height: 82px;
	${media.xs`height: 131px;`}
`

const DropMenu = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-end;
	&:hover #dropdown {
		display: ${({isConnected}) => (isConnected ? `block` : `none`)};
	}
`

const LogoContent = styled.div`
	padding: 0rem;
	display: flex;
	align-items: center;
	margin-left: 0.5rem;
`
const Logo = styled.img`
	width: 4.5rem;
	padding: 0rem;
	cursor: pointer;
`

const LogoText = styled.span`
	margin-left: 0.5rem;
	cursor: pointer;
	@media screen and (min-width: 0px) and (max-width: 992px) {
		display: none;
	}
`

const BrandName = styled.p`
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.black}`)};
	font-size: 1.2rem;
	font-weight: bold;
	font-family: 'bicubik';
	display: flex;
	justify-content: center;
	align-items: center;
	letter-spacing: 0.1rem;
	margin-top: -0.3rem;
	& span {
		color: ${palette.aqua_blue};
		font-family: 'bicubik';
	}
`
const TagLine = styled.p`
	color: ${palette.aqua_blue};
	font-family: 'bicubik';
	font-size: 0.64rem;
	margin-top: -1.2rem;
	letter-spacing: 0.15rem;
	text-transform: uppercase;
	text-align: center;
	& span {
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.black}`)};
	}
`

const ButtonWrapper = styled.div`
	position: relative;
	transition: all 0.5s ease-in-out;
	width: fit-content;
	&::before {
		position: absolute;
		transform: matrix(1, 0.05, 0.01, 1, 0, 0);
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0;
		background: ${palette.blue_gradient_shadow};
		filter: blur(7.60982px);
		border-radius: 2rem;
	}
	&:hover {
		&::before {
			background: none;
			filter: blur(7.60982px);
			border-radius: 2rem;
		}
	}
`

const ConnectButton = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	font-family: 'bicubik';
	letter-spacing: 0.15em;
	font-size: 0.8rem;
	background: ${({type, isDarkTheme}) => (isDarkTheme ? `${palette.black2}` : handleBackgroundType(type))};
	display: inline-block;
	margin: 0.4rem 0.5rem 0 0.5rem;
	padding: 0rem 0.8rem 0 0.8rem;
	height: 40px;
	line-height: 40px;
	font-weight: 600;
	border: 1px solid ${({type}) => handleBorderType(type)};
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.white}`)};
	border-radius: 2rem;
	text-align: center;
	disabled: ${({disabled}) => (disabled ? true : false)};
	cursor: ${({disabled}) => (disabled ? 'no-drop' : 'pointer')};
	// text-overflow: ellipsis;
	overflow: hidden;
	min-width: 8.75rem;
	// max-width: 16.72rem;
	white-space: nowrap;
	transition: all 0.5s ease-in-out;
	&:hover {
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_black}` : `${palette.white}`)};
		background: ${({isDarkTheme, type}) => (isDarkTheme ? handleBackgroundType(type) : `${palette.black2}`)};
	}
	${media.xs`
        max-width: 13rem;
        font-size: 0.42rem;
        min-width: 5rem;
    `}
	${media.sm`
    min-width: 8.75rem;
    `}
`

const spinnerAnimation = keyframes`
	to { -webkit-transform: rotate(360deg); }
`

const Processing = styled(AiOutlineLoading3Quarters)`
	animation: ${spinnerAnimation} 1.5s linear infinite;
	margin-left: 0.2rem;
`
const OpenSideNav = styled.div`
	display: none;
	margin-left: 1rem;
	font-size: 1.5rem;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	${media.xs`margin-left: 0.2rem;`}
	@media screen and (min-width: 0px) and (max-width: 992px) {
		display: block;
	}
`

const CloseSideNav = styled(OpenSideNav)`
	font-size: 1.2rem;
`

const FlexRight = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`

export default NavBar
