import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import AppContext from '../api/AppContext'
import DatePicker from 'react-datepicker'
import { palette } from '../Colors'
import moment from 'moment'
import { handleBackgroundType } from '../components/Button'

const DatePickerModal = ({ setIsDatePickerModalOpen, handleGoToTimeStamp, timeFrame }) => {
	const context = useContext(AppContext)
	const { isDarkTheme } = context

	const [endDate, setEndDate] = useState(new Date())
	const [startDate, setStartDate] = useState(new Date())
	const [activeBorder, setActiveBorder] = useState('Date')

	const [isCustomRangeCalenderOpen, setIsCustomRangeCalenderOpen] = useState(false)
	const [isGotoCalenderOpen, setIsGotoCalenderOpen] = useState(true)
	const [customRange, setCustomRange] = useState(false)

	return (
		<>
			<Modal
				open={true}
				onClose={() => {
					setIsDatePickerModalOpen(false)
				}}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: `${isDarkTheme ? 'helpModal' : 'helpModalLightMode'}`
				}}
				showCloseIcon={false}
				styles={{ zIndex: 2 }}>
				<ModalWrapper>
					<div>
						<Head direction="row" align="center" justify="space-between">
							<P>Go to</P>
							<CloseButton
								src="/images/close-modal.svg"
								alt="close"
								onClick={() => {
									setIsDatePickerModalOpen(false)
								}}
							/>
						</Head>
						<Body>
							<Flex isDarkTheme={isDarkTheme}>
								<CustomBtn
									activeBorder={activeBorder === 'Date'}
									isDarkTheme={isDarkTheme}
									onClick={() => {
										setIsGotoCalenderOpen(!isGotoCalenderOpen)
										setIsCustomRangeCalenderOpen(false)
										setActiveBorder('Date')
									}}>
									Date
								</CustomBtn>
								<CustomBtn
									isDarkTheme={isDarkTheme}
									activeBorder={activeBorder === 'CustomRange'}
									onClick={() => {
										setIsCustomRangeCalenderOpen(!isCustomRangeCalenderOpen)
										setIsGotoCalenderOpen(false)
										setCustomRange(true)
										setActiveBorder('CustomRange')
									}}>
									Custom Range
								</CustomBtn>
							</Flex>

							<Calender id={`${isDarkTheme ? 'darkModelCalender' : 'lightModelCalender'}`}>
								{isCustomRangeCalenderOpen ? (
									<>
										<DatePicker
											selected={startDate}
											onChange={(date) => setStartDate(date)}
											selectsStart
											startDate={startDate}
											endDate={endDate}
											dateFormat="dd/MM/yyyy h:mm aa"
											showTimeSelect
											timeFormat="HH:mm"
											timeIntervals={timeFrame === '15m' ? 15 : timeFrame === '1m' ? 1 : timeFrame === '5m' ? 5 : timeFrame === '4h' ? 240 : timeFrame === '1h' ? 60 : 1440}
											timeCaption="time"
										/>
										<DatePicker
											showTimeSelect
											timeFormat="HH:mm"
											timeIntervals={timeFrame === '15m' ? 15 : timeFrame === '1m' ? 1 : timeFrame === '5m' ? 5 : timeFrame === '4h' ? 240 : timeFrame === '1h' ? 60 : 1440}
											timeCaption="time"
											selected={endDate}
											onChange={(date) => setEndDate(date)}
											selectsEnd
											dateFormat="dd/MM/yyyy h:mm aa"
											startDate={startDate}
											endDate={endDate}
											minDate={startDate}
										/>
									</>
								) : isGotoCalenderOpen ? (
									<Div>
										<DatePicker
											selected={startDate}
											open={true}
											onChange={(date) => setStartDate(date)}
											showTimeSelect
											timeFormat="HH:mm"
											timeIntervals={timeFrame === '15m' ? 15 : timeFrame === '1m' ? 1 : timeFrame === '5m' ? 5 : timeFrame === '4h' ? 240 : timeFrame === '1h' ? 60 : 1440}
											timeCaption="time"
											dateFormat="dd/MM/yyyy h:mm aa"
										/>
									</Div>
								) : null}
							</Calender>
						</Body>
					</div>
					<Footer>
						<Button
							borderActive
							borderRadius
							isDarkTheme={isDarkTheme}
							margin={'0rem 0.5rem 0 0'}
							onClick={() => {
								setIsDatePickerModalOpen(false)
								setStartDate('')
								setEndDate('')
							}}>
							Cancle
						</Button>
						<Button
							borderActive
							borderRadius
							isDarkTheme={isDarkTheme}
							onClick={() => {
								setIsDatePickerModalOpen(false)
								let dateStart = moment(startDate).add(5, 'hour').utc().unix()
								let dateEnd = moment(endDate).add(5, 'hour').utc().unix()
								isCustomRangeCalenderOpen ? handleGoToTimeStamp(dateStart, dateEnd, customRange) : handleGoToTimeStamp(dateStart)
							}}>
							Go to
						</Button>
					</Footer>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
`
const P = styled.p`
	color: ${palette.off_white};
`
const Body = styled.div` padding:  0.5rem !important;`

const Calender = styled.div`
	display: flex;
	margin-top: 0.5rem;
`
const Div = styled.div`
	display: flex;
	justify-content: center;
`
const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
	padding:  0.5rem !important;
`

const Flex = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0rem 1.5rem;
	border-bottom: 0.1px solid ${({ type, isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.white}`)};

`
const Head = styled.div`
	display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({ direction }) => direction};
	align-items: ${({ align }) => align};
	justify-content: ${({ justify }) => justify};
	margin: ${({ margin }) => `${margin}`};
	padding: 0.5rem 1.5rem 0.5rem;
`

const CloseButton = styled.img`
	cursor: pointer;
`

const Button = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	font-family: 'bicubik';
	letter-spacing: 0.15em;
	font-size: 0.8rem;
	background: ${({ type, isDarkTheme }) => (isDarkTheme ? `${palette.black2}` : handleBackgroundType(type))};
	display: inline-block;
	margin: ${({ margin }) => (margin ? `${margin}` : null)};
	height: 35px;
	line-height: 35px;
	font-weight: 600;
	border: ${({ borderActive }) => (borderActive ? `1px solid ${palette.aqua_blue}` : null)};
	border-bottom: ${({ activeBorder }) => (activeBorder ? `4px solid ${palette.aqua_blue}` : null)};
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.white}`)};
	border-radius: ${({ borderRadius }) => (borderRadius ? '1rem' : null)};
	text-align: center;
	cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
	overflow: hidden;
	min-width: 5.75rem;
	white-space: nowrap;
	// transition: all 0.5s ease-in-out;
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.white}`)};
		background: ${({ isDarkTheme, type }) => (isDarkTheme ? handleBackgroundType(type) : `${palette.black2}`)};
	}
`
const CustomBtn = styled(Button)`
	height: 40px;
	line-height: 40px;
	min-width: 8.75rem;
	background: transparent;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.black2}`)};

	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.aqua_blue}`)};
		background: transparent;
	}
`
export default DatePickerModal
