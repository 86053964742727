import React, {useContext, useEffect, useState} from 'react'
import {AiFillThunderbolt} from 'react-icons/ai'
import styled from 'styled-components'
import {palette} from '../Colors'
import AppContext from '../api/AppContext'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'

const SubNavBar = ({trendingCoin, setTrendingCoin}) => {
	const context = useContext(AppContext)
	const {isDarkTheme, scrollPosition, setShowGraph, setRequiredPair, setGraphLoading} = context
	const [trendingApiData, setTrendingApiData] = useState([])
	const navigate = useNavigate()

	const trendingApi = async () => {
		const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/trending`)
		// const response = await axios.get(`https://e423-115-186-45-65.ngrok.io/trending/data`)
		//const response = await axios.get('http://localhost:7272/chart/trending')
		setTrendingApiData(response.data.topTrend)
	}

	useEffect(() => {
		trendingApi()
		//setTrendingCoin(trendingCoin)
	}, [trendingCoin])

	const filterTrending =
		trendingCoin === 'All coin'
			? trendingApiData
			: trendingApiData.filter((value) => {
					return value.chain_slug === trendingCoin ? true : false
			  })
	// default chart - without scroll logic
	const pairDetailChart = (id, ind) => {
		setGraphLoading(true)
		setRequiredPair(id)

		if (id.chain_slug === 'ethereum') {
			navigate(`/ethereum/${id.base_token_address}`)
			setTrendingCoin('ethereum')
		} else if (id.chain_slug === 'binance') {
			navigate(`/binance/${id.base_token_address}`)
			setTrendingCoin('binance')
		} else {
			navigate(`/binance/${id.base_token_address}`)
			setTrendingCoin('polygon')
		}
		setShowGraph(false)
		setTimeout(() => {
			setGraphLoading(false)
			setShowGraph(true)
		}, 1000)
	}
	return (
		<Wrapper isDarkTheme={isDarkTheme} scroll={scrollPosition}>
			<MianHeading isDarkTheme={isDarkTheme} scroll={scrollPosition}>
				<BoltIcon isDarkTheme={isDarkTheme} />
				<SubHeading isDarkTheme={isDarkTheme}>Trending</SubHeading>
			</MianHeading>
			<ScrollContent>
				{filterTrending.map((value, index) => {
					let str = value.base_token_symbol
					if (str.length > 11) {
						str = str.slice(0, -7)
					}
					return (
						<div key={index}>
							<List
								isDarkTheme={isDarkTheme}
								onClick={() => {
									pairDetailChart(value, index)
									setShowGraph(false)
								}}>
								<Text>#{index + 1}</Text>
								<Text>{str}</Text>
								{value.chain_name === 'Ethereum' && <Icons src="/images/ethereum.png" alt="logo" />}
								{value.chain_name === 'Binance Smart Chain' && <Icons src="/images/binance.png" alt="logo" />}
								{value.chain_name === 'Polygon' && <Icons src="/images/polygon.png" alt="logo" />}
								{value.chain_name === 'Avalanche C-chain' && <Icons src="/images/avalanch.png" alt="logo" />}
							</List>
						</div>
					)
				})}
			</ScrollContent>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	width: 100%;
	min-height: 3.3rem;
	padding: 1rem;
	background: ${({scroll, isDarkTheme}) => (isDarkTheme ? (scroll == 0 ? 'rgba(4, 6, 9, 1)' : `${palette.transparent_black}`) : `${palette.transparent_white}`)};
	position: relative;
	box-shadow: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_mode_shadow}` : `${palette.light_mode_shadow}`)};
	position: fixed;
	z-index: 3;
`

const MianHeading = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	background: ${({scroll, isDarkTheme}) => (isDarkTheme ? (scroll == 0 ? 'rgba(4, 6, 9, 1)' : `${palette.black}`) : `${palette.Gray95}`)};
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
	top: 0;
	bottom: 0;
	left: 0;
	padding: 1rem;
	font-weight: bold;
`

const ScrollContent = styled.div`
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	padding-left: 7rem;
	gap: 1rem;
	/* width */
	::-webkit-scrollbar {
		display: none;
		height: 3px !important;
	}
`

const List = styled.div`
	display: flex;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
	&:hover {
		color: ${palette.aqua_blue};
	}
`

const Text = styled.p`
	font-size: 14px;
	margin: 0 0.3rem 0 0;
	white-space: pre;
`

const SubHeading = styled.p`
	font-size: 14px;
	margin: 0 0.3rem 0 0;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
`

const Icons = styled.img`
	width: 20.2px;
	height: 20.2px;
	border-radius: 3rem;
`

const BoltIcon = styled(AiFillThunderbolt)`
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
`

export default SubNavBar
