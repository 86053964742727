import React, {useEffect, useState, useContext, useRef} from 'react'
import {createChart, CrosshairMode} from 'lightweight-charts'
import styled from 'styled-components'
import {AiOutlineUp} from 'react-icons/ai'
import {AiOutlineWallet} from 'react-icons/ai'
import DatePicker from 'react-datepicker'
import AppContext from '../api/AppContext'
import moment from 'moment'
import {palette} from '../Colors'
import axios from 'axios'
// import useWindowSize from '../hooks/useWindowSize'
import {daysDifference, startOfUnixTimeStamp, daysOf_5m_timeBucket, daysOf_1h_timeBucket, daysOf_15m_timeBucket, daysOf_4h_timeBucket, daysOf_1d_timeBucket, daysOf_1m_timeBucket} from './Constants'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const MultiGraph = ({value, column, timeInterVal}) => {
	const chartCreation = useRef()
	// const {width, height} = useWindowSize()
	const context = useContext(AppContext)
	const {isDarkTheme, setShowGraph, requiredPair, chartStartDate} = context
	// constants
	const timeFrameArray = ['1m', '5m', '15m', '1h', '4h', '1d']
	const intervalArray = ['6m', '3m', '1m', '5d', '1d']
	const chartApplyOptions = {
		timeScale: {
			// rightOffset: 12,
			barSpacing: 1,
			fixLeftEdge: false,
			rightBarStaysOnScroll: true,
			borderVisible: true,
			borderColor: `${palette.aqua_blue}`,
			visible: true,
			timeVisible: true,
			secondsVisible: false
		}
	}
	const chartOptions = {
		width: `${column === 1 ? 1310 : column === 2 ? 642 : column === 3 ? 420 : ''}`,
		height: `${column === 1 ? 1000 : column === 2 ? 479 : column === 3 ? 320 : ''}`,
		layout: {
			backgroundColor: `${palette.black}`,
			textColor: `${palette.chart_text}`
		},
		rightPriceScale: {
			scaleMargins: {
				top: 0.4,
				bottom: 0.15
			}
		},
		grid: {
			vertLines: {
				color: `${palette.chart_lines}`
			},
			horzLines: {
				color: `${palette.chart_lines}`
			}
		},
		crosshair: {
			mode: CrosshairMode.Normal
		},
		priceScale: {
			borderColor: `${palette.aqua_blue}`
		},
		localization: {
			dateFormat: 'yyyy-MM-dd'
		},
		timeScale: {
			borderColor: `${palette.aqua_blue}`
		}
	}

	// Refs
	const chartDataRef = useRef(null)
	// const fromDateRef = useRef(chartStartDate)
	const fromDateRef = useRef(moment().subtract(20, 'hours').utc().format('YYYY-MM-DD HH:mm:ss'))
	const toDateRef = useRef(moment().utc().format('YYYY-MM-DD HH:mm:ss'))
	let candleSticksDataRef = useRef()
	let chartUseRef = useRef('')
	let timeFrame = useRef('15m')
	let interVal = useRef('')

	const [activeTimeFrame, setActiveTimeFrame] = useState('15m')
	const [activeTimeInterval, setActiveTimeInterval] = useState('')

	let timeStamp = []
	let callcount = 0
	let increment = 0
	let dataLength = []

	const [iconState, setIconState] = useState(false)
	const [isCalenderOpen, setIsCalenderOpen] = useState(false)
	const [isCustomRangeCalenderOpen, setIsCustomRangeCalenderOpen] = useState(false)
	const [isGotoCalenderOpen, setIsGotoCalenderOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()
	useEffect(() => {
		createNewChart()
	}, [])

	const createNewChart = () => {
		const chart = createChart(chartCreation.current, chartOptions)
		chart.applyOptions(chartApplyOptions)
		let timeScale = chart.timeScale()

		chartUseRef.current = chart

		const candlestick = chart.addCandlestickSeries({
			priceFormat: {
				type: 'custom',
				minMove: '0.00000001',
				formatter: (price) => {
					return parseFloat(price).toFixed(8)
				}
			}
		})

		candleSticksDataRef.current = candlestick

		// todo.. re-write 'to' and 'from' depending on 'timeBucket'
		// 1d : 15 days (# of records = 15)
		// 4h : 6 days (# of records = 36)
		// 1h : 3 days (# of records = 36)
		// 15m : 1 day (# of records = 96)
		// 1m : 1 hour (# of records = 60)

		timeScale.subscribeVisibleTimeRangeChange(async () => {
			const t = timeScale.getVisibleRange()
			timeStamp.push(t)
			// only on chart scroll to left
			if (timeStamp.length > 10) {
				if (callcount === 0) {
					if (
						timeStamp[timeStamp.length - 2].from === timeStamp[timeStamp.length - 1].from &&
						timeStamp[timeStamp.length - 2].from === timeStamp[timeStamp.length - 3].from &&
						timeStamp[timeStamp.length - 2].from === timeStamp[timeStamp.length - 4].from &&
						timeStamp[timeStamp.length - 2].from === timeStamp[timeStamp.length - 5].from &&
						timeStamp[timeStamp.length - 2].from === timeStamp[timeStamp.length - 6].from &&
						timeStamp[timeStamp.length - 2].from === timeStamp[timeStamp.length - 7].from
					) {
						callcount = callcount + 1
						setLoading(true)

						if (timeInterVal === '1m') {
							toDateRef.current = fromDateRef.current
							fromDateRef.current = moment(fromDateRef.current).subtract(daysOf_1m_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
						} else if (timeInterVal === '5m') {
							toDateRef.current = fromDateRef.current
							fromDateRef.current = moment(fromDateRef.current).subtract(daysOf_5m_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
						} else if (timeInterVal === '15m') {
							toDateRef.current = fromDateRef.current
							fromDateRef.current = moment(fromDateRef.current).subtract(daysOf_15m_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
						} else if (timeInterVal === '1h') {
							toDateRef.current = fromDateRef.current
							fromDateRef.current = moment(fromDateRef.current).subtract(daysOf_1h_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
						} else if (timeFrame.current === '4h') {
							toDateRef.current = fromDateRef.current
							fromDateRef.current = moment(fromDateRef.current).subtract(daysOf_4h_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
						} else if (timeInterVal === '1d') {
							toDateRef.current = fromDateRef.current
							fromDateRef.current = moment(fromDateRef.current).subtract(daysOf_1d_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
						}

						let response = await apiRequest({timeBucket: timeFrame.current, from: fromDateRef.current, to: toDateRef.current})
						let data = processChartData(response.data)
						if (data != null) {
							let chartExsitingData = chartDataRef?.current
							let concatenatedData = chartExsitingData.concat(data)
							concatenatedData = concatenatedData.sort(function (a, b) {
								return a?.time - b?.time
							})
							concatenatedData = [...new Map(concatenatedData.map((item) => [item['time'], item])).values()]
							chartDataRef.current = concatenatedData
							setLoading(false)
						}
						setLoading(false)
						dataLength.push(chartDataRef.current?.length)
						candlestick.setData(chartDataRef.current)
						new ResizeObserver((entries) => {
							if (entries.length === 0 || entries[0].target !== chartDataRef) {
								return
							}
							const newRect = entries[0].contentRect
							chartDataRef.applyOptions({height: newRect.height, width: newRect.width})
						}).observe(chartDataRef)
						if (dataLength.length > 1) {
							let test = dataLength[increment] - dataLength[increment - 1]
							if (test > 400) {
								chart.timeScale().setVisibleLogicalRange({from: test - 200, to: test})
							} else if (test > 200) {
								chart.timeScale().setVisibleLogicalRange({from: test - 100, to: test})
							} else if (test > 100) {
								chart.timeScale().setVisibleLogicalRange({from: test - 80, to: test})
							} else if (test > 50) {
								chart.timeScale().setVisibleLogicalRange({from: test - 42, to: test})
							} else if (test > 25) {
								chart.timeScale().setVisibleLogicalRange({from: test - 20, to: test})
							} else if (test > 10) {
								chart.timeScale().setVisibleLogicalRange({from: 0, to: test})
							}
						}
						setTimeout(() => {
							callcount = callcount - 1
							increment = increment + 1
						}, 1000)
					}
				}
			}
		})

		getDataFromDb(candlestick, chart)
	}

	// common function to get data from db
	const apiRequest = async ({timeBucket, from, to}) => {
		const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/data`, {
			pairId: value.pair_id,
			exchangeType: value.exchange_type,
			tokenAddress: value.base_token_address,
			timeBucket: timeBucket,
			from: from,
			to: to
		})
		return response
	}

	// common function for processing db response data
	const processChartData = (data) => {
		if (data?.status) {
			let _newdata = data?.data?.map((d) => {
				return {time: moment(d.time).add(5, 'hours').unix(), open: d.open, high: d.high, low: d.low, close: d.close}
			})
			let chartData = _newdata.filter((d) => d.time > startOfUnixTimeStamp)
			return chartData
		}
		return null
	}

	const getDataFromDb = async (candlestick, chart) => {
		setLoading(true)
		const request = await apiRequest({timeBucket: timeInterVal, from: fromDateRef.current, to: toDateRef.current})
		const data = processChartData(request.data)
		if (data != null) {
			chartDataRef.current = data
		} else {
			chartDataRef.current = []
		}
		setLoading(false)
		candlestick.setData(chartDataRef.current)

		////////LEGENDS////////////////////////////////

		const symbolName = `${value.pair_symbol}`
		const container = document.getElementById('container')
		container.style = `position: relative;`

		//const legend = document.createElement('div')
		//legend.style = `position: absolute; left: 12px; top: 12px; z-index: 2; font-size: 14px; font-family: sans-serif; line-height: 18px; font-weight: 300;`
		//legend.style.color = 'white'
		//container.appendChild(legend)
		const getLastBar = () => data[data.length - 1]

		const formatPrice = (price) => parseFloat(price).toFixed(8)
		const setTooltipHtml = (name, open, high, low, close) => {
			//legend.innerHTML = `<div style="font-size: 16px; margin: 4px 0px; font-weight:bold;font-family: 'Montserrat-Regular', sans-serif;">${name} </div><div style="font-size: 12px; margin: 4px 0px; color:${palette.aqua_blue};font-weight:500;"> <span style="color:white">O: </span>${open}&nbsp;<span style="color:white">H: </span>${high}&nbsp;<span style="color:white">L: </span>${low}&nbsp;<span style="color:white">C: </span>${close}</div>`
		}

		const updateLegend = (param) => {
			const validCrosshairPoint = !(param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0)
			const bar = validCrosshairPoint ? param : getLastBar()
			const open = validCrosshairPoint ? param.seriesPrices.get(candlestick).open : bar.open
			const high = validCrosshairPoint ? param.seriesPrices.get(candlestick).high : bar.high
			const low = validCrosshairPoint ? param.seriesPrices.get(candlestick).low : bar.low
			const close = validCrosshairPoint ? param.seriesPrices.get(candlestick).close : bar.close
			const formattedOpen = formatPrice(open)
			const formattedHigh = formatPrice(high)
			const formattedLow = formatPrice(low)
			const formattedClose = formatPrice(close)
			setTooltipHtml(symbolName, formattedOpen, formattedHigh, formattedLow, formattedClose)
		}

		chart.subscribeCrosshairMove(updateLegend)
		updateLegend(undefined)
		chart.timeScale().setVisibleLogicalRange({from: data.length - 50, to: data.length})
	}

	// chart header timeframe filter
	const getTimeFrameData = async () => {
		if (timeInterVal === '1m') {
			fromDateRef.current = moment().utc().subtract(daysOf_1m_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
			toDateRef.current = moment().utc().format('YYYY-MM-DD HH:mm:ss')
		} else if (timeInterVal === '15m') {
			fromDateRef.current = moment().utc().subtract(daysOf_15m_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
			toDateRef.current = moment().utc().format('YYYY-MM-DD HH:mm:ss')
		} else if (timeInterVal === '5m') {
			fromDateRef.current = moment().utc().subtract(daysOf_5m_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
			toDateRef.current = moment().utc().format('YYYY-MM-DD HH:mm:ss')
		} else if (timeInterVal === '1h') {
			fromDateRef.current = moment().utc().subtract(daysOf_1h_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
			toDateRef.current = moment().utc().format('YYYY-MM-DD HH:mm:ss')
		} else if (timeInterVal === '4h') {
			fromDateRef.current = moment().utc().subtract(daysOf_4h_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
			toDateRef.current = moment().utc().format('YYYY-MM-DD HH:mm:ss')
		} else if (timeInterVal === '1d') {
			fromDateRef.current = moment().utc().subtract(daysOf_1d_timeBucket, 'day').format('YYYY-MM-DD HH:mm:ss')
			toDateRef.current = moment().utc().format('YYYY-MM-DD HH:mm:ss')
		}
		setActiveTimeInterval('')
		setLoading(true)
		const request = await apiRequest({
			timeBucket: timeFrame.current,
			from: fromDateRef.current,
			to: toDateRef.current
		})
		const data = processChartData(request.data)
		if (data != null) {
			chartDataRef.current = data
		} else {
			chartDataRef.current = []
		}
		candleSticksDataRef.current.setData(chartDataRef.current)
		setLoading(false)
		chartUseRef.current.timeScale().setVisibleLogicalRange({from: chartDataRef?.current?.length - 50, to: chartDataRef?.current?.length})
	}

	// chart footer time interval filter
	const getTimeIntervalData = async () => {
		let date = moment().format('YYYY-MM-DD HH:mm:ss')
		if (interVal.current === '6m') {
			timeFrame.current = '1d'
			setActiveTimeFrame('1d')
			fromDateRef.current = moment(date).utc().subtract(6, 'months').format('YYYY-MM-DD HH:mm:ss')
		} else if (interVal.current === '3m') {
			timeFrame.current = '4h'
			setActiveTimeFrame('4h')
			fromDateRef.current = moment(date).utc().subtract(3, 'months').format('YYYY-MM-DD HH:mm:ss')
		} else if (interVal.current === '1m') {
			timeFrame.current = '15m'
			setActiveTimeFrame('15m')
			fromDateRef.current = moment(date).utc().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss')
		} else if (interVal.current === '5d') {
			timeFrame.current = '5m'
			setActiveTimeFrame('5m')
			fromDateRef.current = moment(date).utc().subtract(5, 'day').format('YYYY-MM-DD HH:mm:ss')
		}
		if (interVal.current === '1d') {
			timeFrame.current = '1m'
			setActiveTimeFrame('1m')
			fromDateRef.current = moment(date).utc().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')
		}
		setLoading(true)

		const request = await apiRequest({timeBucket: timeFrame.current, from: fromDateRef.current, to: moment().utc().format('YYYY-MM-DD HH:mm:ss')})
		const data = processChartData(request.data)
		if (data != null) {
			chartDataRef.current = data
		} else {
			chartDataRef.current = []
		}
		candleSticksDataRef.current.setData(chartDataRef.current)
		setLoading(false)
		chartUseRef.current.timeScale().setVisibleLogicalRange({from: chartDataRef?.current?.length - 50, to: chartDataRef?.current?.length})
	}

	return (
		<>
			<Flexed>
				<CoinDetails>
					<InnerDiv>
						{/* <div>
							<SimpleIcon src="/images/binance.png" />
						</div> */}
						<div>
							<Heading isDarkTheme={isDarkTheme} fontWeight="normal">
								{value.pair_name}
							</Heading>
							<Heading isDarkTheme={isDarkTheme} fontWeight="normal">
								{value.pair_symbol}
							</Heading>
						</div>
					</InnerDiv>
					<Hyperlink
						href={
							value.chain_slug === 'ethereum'
								? `${process.env.REACT_APP_ETH_URL}/${value.base_token_address}`
								: value.chain_slug === 'binance'
								? `${process.env.REACT_APP_BSC_URL}/${value.base_token_address}`
								: `${process.env.REACT_APP_POLY_URL}/${value.base_token_address}`
						}
						target="_blank">
						{/* <Icon src={value.chain_slug === 'ethereum' ? '/images/ethereum.png' : value.chain_slug === 'binance' ? '/images/bscScan.webp' : '/images/polygon.png'} /> */}
					</Hyperlink>
				</CoinDetails>

				<Div chartOptions={chartOptions} column={column}>
					<Header isDarkTheme={isDarkTheme}>
						{timeFrameArray?.map((i) => {
							return (
								<CursomText
									isDarkTheme={isDarkTheme}
									active={activeTimeFrame === i}
									onClick={() => {
										timeFrame.current = i
										setActiveTimeFrame(i)
										getTimeFrameData()
									}}>
									{i}
								</CursomText>
							)
						})}
						<IconCover boder onClick={() => setIconState(!iconState)} isDarkTheme={isDarkTheme}>
							<Arrow iconState={iconState} isDarkTheme={isDarkTheme} />
						</IconCover>
						<Border />

						{/* <Icon marginLeft="5px" active src="/images/candlestick.svg" /> */}
					</Header>
					<Wrapper>
						<div ref={chartCreation}>
							<div id="container"></div>
						</div>
						{loading && (
							<LoadingPanelContent>
								<LoadingPanel src="/images/Preloader.gif" />
							</LoadingPanelContent>
						)}
					</Wrapper>
					<Footer isDarkTheme={isDarkTheme}>
						{intervalArray?.map((i) => {
							return (
								<Text
									isDarkTheme={isDarkTheme}
									active={activeTimeInterval === i}
									onClick={() => {
										interVal.current = i
										setActiveTimeInterval(i)
										getTimeIntervalData()
									}}>
									{i}
								</Text>
							)
						})}

						<Border />
						<IconCover margin="0 5px 0 5px ">
							<Wallet isDarkTheme={isDarkTheme} onClick={() => setIsCalenderOpen(!isCalenderOpen)} />
						</IconCover>
						{isCalenderOpen ? (
							<>
								<button onClick={() => setIsCustomRangeCalenderOpen(!isCustomRangeCalenderOpen)}>Custom Range</button> <button onClick={() => setIsGotoCalenderOpen(!isGotoCalenderOpen)}>GoTO</button>
							</>
						) : null}
						{isCustomRangeCalenderOpen ? (
							<>
								<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} selectsStart startDate={startDate} endDate={endDate} />
								<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} />
							</>
						) : isGotoCalenderOpen ? (
							<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} timeInputLabel="Time:" dateFormat="MM/dd/yyyy h:mm aa" showTimeInput />
						) : null}
					</Footer>
				</Div>
			</Flexed>
		</>
	)
}

const Div = styled.div`
	width: 100%;
	min-height: ${({column, chartOptions}) => (column === 1 ? chartOptions.width === '1300px' : column === 2 ? chartOptions.width === '600px' : column === 3 ? chartOptions.width === '540px' : '')};
	min-width: ${({column}) => (column === 1 ? '100%' : column === 2 ? '50%' : column === 3 ? '33%' : '')};
	//border: 2px solid ${palette.aqua_blue};
	border-top: 1px solid rgb(255 255 255 / 20%);
`

const Flexed = styled.div`
	display: flex;
	flex-direction: column;
`

const CoinDetails = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	object-fit: cover;
`

const Header = styled.div`
	background: ${({isDarkTheme}) => (isDarkTheme ? ` ${palette.black}` : ` ${palette.white}`)};
	width: 43px;
	height: 35px;
	//border-bottom: 2px solid ${palette.aqua_blue};
	border-bottom: 1px solid rgb(255 255 255 / 20%);
	display: flex;
	align-items: center;
	padding-left: 4px;
`

const Text = styled.p`
	color: ${({active, isDarkTheme}) => (active ? ` ${palette.aqua_blue}` : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	padding: 7px;
	border-radius: 5px;
	cursor: pointer;
	margin: 0px;
	font-size: 14px !important;
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.chart_lines}` : `${palette.charcol}`)};
	}
`

const Hyperlink = styled.a`
	all: unset;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	color: ${palette.off_white};
	width: 10px;
	padding: 5px 15px;
	border-radius: 4px;
	cursor: pointer;
`

const CursomText = styled.p`
	color: ${({active, isDarkTheme}) => (active ? ` ${palette.aqua_blue}` : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	padding: 7px;
	border-radius: 5px;
	cursor: pointer;
	margin: 0px;
	font-size: 14px !important;
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.chart_lines}` : `${palette.charcol}`)};
	}
`

const InnerDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const IconCover = styled.div`
	padding: 7px;
	border-radius: 5px;
	margin-right: 5px;
	margin: ${({margin}) => margin};

	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.chart_lines}` : `${palette.charcol}`)};
	}
`

const Border = styled.div`
	height: 42px;
	//border-right: 2px solid ${palette.aqua_blue};
	border-right: 1px solid rgb(255 255 255 / 20%);
`

const Footer = styled(Header)`
	//border-top: 2px solid ${palette.aqua_blue};
	border-top: 1px solid rgb(255 255 255 / 20%);
`

const Heading = styled.p`
	padding: ${({padding}) => padding};
	margin: ${({margin}) => margin || '6px'};
	color: ${({color, isDarkTheme}) => (color ? color : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	background-color: ${({bgColor}) => bgColor};
	font-weight: ${({fontWeight}) => fontWeight};
	font-size: 0.8rem;
`
const LoadingPanel = styled.img`
	width: 40%;
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	left: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	filter: ${({isDarkTheme}) => (isDarkTheme ? `invert(61%) sepia(26%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)` : `invert(61%) sepia(2%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)`)};
`
const LoadingPanelContent = styled.div`
	width: 100%;
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	left: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	background: ${palette.overlay};
`
const Wrapper = styled.div`
	position: relative;
`

const Wallet = styled(AiOutlineWallet)`
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
`

const Arrow = styled(AiOutlineUp)`
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	transform: ${({iconState}) => (iconState ? `rotate(180deg)` : ``)};
`

export default MultiGraph
