import axios from 'axios'
import React, {useState, useEffect} from 'react'
import userContext from './AppContext'
const ContextStateApi = (props) => {
	const [isDarkTheme, setIsDarkTheme] = useState(true)
	const [scrollPosition, setScrollPosition] = useState(0)
	const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
	const [requiredPair, setRequiredPair] = useState({})
	const [showGraph, setShowGraph] = useState(false)
	const [chartStartDate, setChartStartDate] = useState(false)
	const [graphLoading, setGraphLoading] = useState(false)
	const [checkReloadState, setCheckReloadState] = useState(true)
	const [multiChartGraph, setMultiChartGraph] = useState(false)

	return (
		<userContext.Provider
			value={{
				multiChartGraph,
				setMultiChartGraph,
				isDarkTheme,
				setIsDarkTheme,
				scrollPosition,
				setScrollPosition,
				isSideMenuOpen,
				setIsSideMenuOpen,
				checkReloadState,
				setCheckReloadState,
				requiredPair,
				setRequiredPair,
				showGraph,
				setShowGraph,
				chartStartDate,
				setChartStartDate,
				graphLoading,
				setGraphLoading
			}}>
			{props.children}
		</userContext.Provider>
	)
}
export default ContextStateApi
