export const palette = {
	pure_white: '#ffffff',
	white: '#f0f0f0',
	transparent_white: '#f0f0f0e0',
	silver: '#2f3032',
	pie_chart_bg: '#09080a',
	binance: '#F3BA2F',
	polygon: '#8248e5',
	ether: '#8d8d8d',
	off_white: '#fafafa', //Text color cards
	theme_Text: '#8c9b94',
	description_Text: '#c1c1c1',
	black: '#040609', // header bg
	black2: '#000000b0',
	transparent_black: 'rgb(4, 6 , 9, 0.8)',
	off_black: '#241E27',
	Gray95: '#f2f2f2',
	light: '#f5f5f5',
	grapes: '#2E2732',
	dark: '#191a1c',
	off_dark: '#2d2d2d',
	hot: '#ed3c16',
	blue_hover: '#166fe5',
	sky: 'rgb(247, 248, 250)',
	cloud: 'rgb(237, 238, 242)',
	gray: '#e3e2e2',
	dark_gray: '#b9babb', // text color bg tranparent  // table borderColor
	gray_light: '#00000021',
	charcol: '#b8b8b8',
	charcol_light: '#585858', // input field border color
	secondary: '#e91e63',
	secondary_light: '#fdeaf1',
	twitter: '#1d9bf0',
	telegram: '#1e8fcc',
	discord: '#485ef4',
	world_map: '#9c27b0',
	overlay: '#00000038',
	glowing_blue: '#00e0ff',
	aqua_blue: '#2596be',
	aqua_blue_bright: '#00dfff',
	blue: '#009eff',
	lightAqua: '#DDFBFF',
	heading: '#EFECEC',
	yellow: '#FFF500',
	pink: '#FF00E5',
	light_pink: '#feb2b2',
	red: '#FF0000',
	green: '#71F063',
	dark_green: '#10ac00',
	dark_mode_shadow: '0px 3px 15px rgba(255, 255, 255, 0.2)',
	light_mode_shadow: '0px 3px 15px #1d1c1c33',
	orange: '#FF8F51',
	blue_gradient_card: 'linear-gradient(to right,#00314e87, #024665b8 , #00314e87)',
	gray_gradient_card: 'linear-gradient(to right,#70707052, #8c8c8cbd , #70707052)',
	blue_gradient_wing: 'radial-gradient(farthest-corner at 54% 100%,#03adf7,#00dfff)',
	green_mirror: '#71f0630a',
	table_green: '#48bb78',
	blue_mirror: '#0085ff0a',
	orange_mirror: '#ff6c000a',
	grayy: '#a0aec0',
	gray_mirror: '#ffffff26',
	chart_text: 'rgba(255, 255, 255, 0.9',
	chart_lines: 'rgba(37, 39, 51, 1)',
	chart_green: '#37a169',
	pink_mirror: '#a981bc26',
	red_mirror: '#ff00000a',
	yellow_mirror: '#faff000a',
	green_gradient: 'linear-gradient(91.88deg,#187600 5.72%,#23a302 95.59%)',
	blue_gradient_list: 'linear-gradient(91.88deg,#00668a 5.72%,#257490 95.59%)',
	blue_gradient: 'linear-gradient(91.88deg,#0085FF 5.72%,#00DFFF 95.59%)',
	gray_gradient: 'linear-gradient(91.88deg,#b2b2b2 5.72%,#ffffff 95.59%)',
	pink_gradient: 'linear-gradient(91.88deg,#Af05ff 5.72%,#FF00E5 95.59%)',
	orange_gradient: 'linear-gradient(91.88deg,#FF6007 5.72%,#FF8F51 95.59%)',
	red_gradient: 'linear-gradient(91.88deg,#cc0601 5.72%,#c7332f 95.59%)',
	yellow_gradient: 'linear-gradient(91.88deg,#dca500 5.72%,#d7ab26 95.59%)',
	green_gradient_shadow: 'linear-gradient(180deg, rgba(66, 255, 0, 0.3) 0%, rgba(219, 255, 0, 0.3) 100%);',
	pink_gradient_shadow: 'linear-gradient(166.96deg, rgba(250, 0, 255, 0.5) 9.41%, rgba(103, 0, 120, 0.45) 176.63%);',
	orange_gradient_shadow: 'linear-gradient(166.96deg, #ff8c48 9.41%, #ff8641 176.63%);',
	gray_gradient_shadow: 'linear-gradient(166.96deg, rgba(255, 255, 255, 0.58) 9.41%, rgba(255, 255, 255, 0.58) 176.63%);',
	blue_gradient_shadow: 'linear-gradient(159.04deg, rgba(0, 223, 255, 0.5) 13.85%, rgba(0, 121, 209, 0.5) 95.84%);',
	red_gradient_shadow: 'linear-gradient(159.04deg,#f00 13.85%,#d10000 95.84%)',
	yellow_gradient_shadow: 'linear-gradient(159.04deg,#ffe20080 13.85%,#bdd10080 95.84%)'
}
