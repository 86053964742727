import React, {useContext} from 'react'
import styled from 'styled-components'
import {Heading, Flexed} from '../styles/shared'
import AppContext from '../api/AppContext'

const NewPairs = () => {
	const context = useContext(AppContext)
	const {isDarkTheme} = context
	return (
		<Flex direction="row" justify="center" align="center">
			<Heading isDarkTheme={isDarkTheme}>In Development Process</Heading>
		</Flex>
	)
}

const Flex = styled(Flexed)`
	height: 100%;
`

export default NewPairs
