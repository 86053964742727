import React, { useContext } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import AppContext from '../api/AppContext'
import MultiSearchBar from '../components/MultiSearchBar'

const SearchModal = ({ open, setOpen, addChart, localItems }) => {
	// const onOpenModal = () => setOpen(true)
	const onCloseModal = () => setOpen(false)
	const context = useContext(AppContext)
	const { isDarkTheme } = context

	return (
		<>
			<Modal
				open={open}
				onClose={() => {
					onCloseModal()
				}}
				showCloseIcon={false}
				start
				isDarkTheme={isDarkTheme}
				classNames={{
					overlay: 'customOverlay',
					modal: `${isDarkTheme ? 'customModal' : 'customModalLight'}`
				}}>
				<MultiSearchBar isDarkTheme={isDarkTheme} addChart={addChart} onCloseModal={onCloseModal} localItems={localItems} />
			</Modal>
		</>
	)
}

export default SearchModal
