import React, {useState, useContext} from 'react'
import Table from 'react-bootstrap/Table'
import styled from 'styled-components'
import {palette} from '../Colors'
import AppContext from '../api/AppContext'
import Button from './Button'
import {useNavigate} from 'react-router-dom'
import {NotFoundText, TableLoading, TableLoadingPanelContent, TableLoadingSpiner} from '../styles/shared'
import {TiArrowSortedDown, TiArrowSortedUp} from 'react-icons/ti'

//todo..functional component
const PairsTableEthereum = ({tableDataEth, loading, selectedPage, setSelectedPage}) => {
	let navigate = useNavigate()
	const context = useContext(AppContext)
	const [sortTableAsc, setSortTableAsc] = useState('Asc')
	const [order, setOrder] = useState('')
	const {isDarkTheme, setCheckReloadState, setGraphLoading, setRequiredPair, setShowGraph} = context

	const pairDetailChart = (id) => {
		setGraphLoading(true)
		setRequiredPair(id)
		setCheckReloadState(false)
		navigate(`/ethereum/${id.base_token_address}`)
		setTimeout(() => {
			setGraphLoading(false)
			setShowGraph(true)
		}, 1000)
	}

	const pageSize = 100
	const pagesItem = Math.ceil(tableDataEth.length / pageSize)
	const nextPage = () => {
		if (selectedPage !== pagesItem) setSelectedPage(selectedPage + 1)
	}
	const prevPage = () => {
		if (selectedPage !== 1) setSelectedPage(selectedPage - 1)
	}
	const startIndex = selectedPage * pageSize - pageSize
	const endIndex = selectedPage * pageSize - 1
	const paginatedTable = tableDataEth.filter((_table, index) => {
		if (index >= startIndex && index <= endIndex) {
			return true
		}
		return false
	})
	const sortTable = (number) => {
		sortTableAsc === 'Asc' ? setSortTableAsc('Dsc') : setSortTableAsc('Asc')
		setOrder(number)
		sortNewTable(sortTableAsc, number)
	}
	const sortNewTable = (sortTableAsc, number) => {
		if (number === 'symbol') {
			sortTableAsc === 'Asc' ? tableDataEth.sort((preValue, nextValue) => (preValue.pair_symbol > nextValue.pair_symbol ? 1 : -1)) : tableDataEth.sort((preValue, nextValue) => (preValue.pair_symbol < nextValue.pair_symbol ? 1 : -1))
			return
		} else if (number === 'price') {
			sortTableAsc === 'Asc' ? tableDataEth.sort((preValue, nextValue) => (preValue.Genre > nextValue.usd_price_latest ? 1 : -1)) : tableDataEth.sort((preValue, nextValue) => (preValue.usd_price_latest < nextValue.usd_price_latest ? 1 : -1))
			return
		} else if (number === 'txn') {
			sortTableAsc === 'Asc' ? tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d > nextValue.usd_volume_30d ? 1 : -1)) : tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d < nextValue.usd_volume_30d ? 1 : -1))
			return
		} else if (number === 'volume') {
			sortTableAsc === 'Asc' ? tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d > nextValue.usd_volume_30d ? 1 : -1)) : tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d < nextValue.usd_volume_30d ? 1 : -1))
			return
		} else if (number === '5m') {
			sortTableAsc === 'Asc' ? tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d > nextValue.usd_volume_30d ? 1 : -1)) : tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d < nextValue.usd_volume_30d ? 1 : -1))
			return
		} else if (number === '1h') {
			sortTableAsc === 'Asc' ? tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d > nextValue.usd_volume_30d ? 1 : -1)) : tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d < nextValue.usd_volume_30d ? 1 : -1))
			return
		} else if (number === '6h') {
			sortTableAsc === 'Asc' ? tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d > nextValue.usd_volume_30d ? 1 : -1)) : tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d < nextValue.usd_volume_30d ? 1 : -1))
			return
		} else if (number === '24h') {
			sortTableAsc === 'Asc' ? tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d > nextValue.usd_volume_30d ? 1 : -1)) : tableDataEth.sort((preValue, nextValue) => (preValue.usd_volume_30d < nextValue.usd_volume_30d ? 1 : -1))
			return
		} else if (number === 'liquidity') {
			sortTableAsc === 'Asc'
				? tableDataEth.sort((preValue, nextValue) => (preValue.usd_liquidity_latest > nextValue.usd_liquidity_latest ? 1 : -1))
				: tableDataEth.sort((preValue, nextValue) => (preValue.usd_liquidity_latest < nextValue.usd_liquidity_latest ? 1 : -1))
			return
		} else if (number === 'fdv') {
			sortTableAsc === 'Asc'
				? tableDataEth.sort((preValue, nextValue) => (preValue.usd_liquidity_latest > nextValue.usd_liquidity_latest ? 1 : -1))
				: tableDataEth.sort((preValue, nextValue) => (preValue.usd_liquidity_latest < nextValue.usd_liquidity_latest ? 1 : -1))
			return
		}
	}
	return (
		<>
			<Div isDarkTheme={isDarkTheme}>
				<Table striped bordered variant={`${isDarkTheme ? 'dark' : ''}`} responsive id="ddd">
					<thead>
						<tr>
							<ListHeading onClick={() => sortTable('symbol')}>TOKEN{order === 'symbol' && sortTableAsc === 'Asc' ? <Uparrow isDarkTheme={isDarkTheme} /> : <DownArrow isDarkTheme={isDarkTheme} />}</ListHeading>
							<ListHeading onClick={() => sortTable('txn')}>TXNS{order === 'txn' && sortTableAsc === 'Asc' ? <Uparrow isDarkTheme={isDarkTheme} /> : <DownArrow isDarkTheme={isDarkTheme} />}</ListHeading>
							<ListHeading onClick={() => sortTable('volume')}>VOLUME{order === 'volume' && sortTableAsc === 'Asc' ? <Uparrow isDarkTheme={isDarkTheme} /> : <DownArrow isDarkTheme={isDarkTheme} />}</ListHeading>
							<ListHeading onClick={() => sortTable('5m')}>5M{order === '5m' && sortTableAsc === 'Asc' ? <Uparrow isDarkTheme={isDarkTheme} /> : <DownArrow isDarkTheme={isDarkTheme} />}</ListHeading>
							<ListHeading onClick={() => sortTable('1h')}>1H{order === '1h' && sortTableAsc === 'Asc' ? <Uparrow isDarkTheme={isDarkTheme} /> : <DownArrow isDarkTheme={isDarkTheme} />}</ListHeading>
							<ListHeading onClick={() => sortTable('6h')}>6H{order === '6h' && sortTableAsc === 'Asc' ? <Uparrow isDarkTheme={isDarkTheme} /> : <DownArrow isDarkTheme={isDarkTheme} />}</ListHeading>
							<ListHeading onClick={() => sortTable('24h')}>24H{order === '24h' && sortTableAsc === 'Asc' ? <Uparrow isDarkTheme={isDarkTheme} /> : <DownArrow isDarkTheme={isDarkTheme} />}</ListHeading>
							<ListHeading onClick={() => sortTable('liquidity')}>LIQUIDITY{order === 'liquidity' && sortTableAsc === 'Asc' ? <Uparrow isDarkTheme={isDarkTheme} /> : <DownArrow isDarkTheme={isDarkTheme} />}</ListHeading>
							<ListHeading onClick={() => sortTable('fdv')}>FDV{order === 'fdv' && sortTableAsc === 'Asc' ? <Uparrow isDarkTheme={isDarkTheme} /> : <DownArrow isDarkTheme={isDarkTheme} />}</ListHeading>
						</tr>
					</thead>
					{!loading ? (
						<>
							{paginatedTable.length > 0 ? (
								<tbody>
									{paginatedTable.map((d, index) => {
										let newSelection = selectedPage - 1
										return (
											<tr key={index}>
												<Flex>
													<ListHeading>#{selectedPage === 1 ? index + 1 : pageSize * newSelection + index + 1}</ListHeading>
													{d.exchange_slug === 'pancakeswap-v2' && <Icons src="/images/pancakeswap.png" alt="logo" />}
													{d.exchange_slug === 'apeswap' && <Icons src="/images/apeswap.png" alt="logo" />}
													{d.chain_name === 'Ethereum' && <Icons src="/images/ethereum.png" alt="logo" />}
													{d.chain_name === 'Binance Smart Chain' && <Icons src="/images/binance.png" alt="logo" />}
													{d.chain_name === 'Polygon' && <Icons src="/images/polygon.png" alt="logo" />}
													<Td
														isDarkTheme={isDarkTheme}
														onClick={() => {
															pairDetailChart(d, index)
														}}>
														{d.pair_symbol}
													</Td>
													{/* <Name>{d.pair_name}</Name> */}
												</Flex>
												<Td isDarkTheme={isDarkTheme}>${d.usd_price_latest}</Td>
												<Td isDarkTheme={isDarkTheme}>{d.usd_volume_30d}</Td>
												<Td isDarkTheme={isDarkTheme}>{d.usd_volume_30d}</Td>
												<Td isDarkTheme={isDarkTheme}>{d.usd_volume_30d}</Td>
												<Td isDarkTheme={isDarkTheme}>{d.usd_volume_30d}</Td>
												<Td isDarkTheme={isDarkTheme}>{d.usd_volume_30d}</Td>
												<Td isDarkTheme={isDarkTheme}>{d.liquidity_change_24h}%</Td>
												<Td isDarkTheme={isDarkTheme}>{d.usd_liquidity_latest}</Td>
											</tr>
										)
									})}
								</tbody>
							) : (
								<tbody>
									<tr>
										<td colSpan={10}>
											<div>
												<NotFoundText>Record not found</NotFoundText>
											</div>
										</td>
									</tr>
								</tbody>
							)}
						</>
					) : (
						<>
							<tbody>
								<tr>
									<td colSpan={10}>
										<TableLoading>
											<TableLoadingPanelContent isDarkTheme={isDarkTheme}>
												<TableLoadingSpiner src="/images/Preloader.gif" />
											</TableLoadingPanelContent>
										</TableLoading>
									</td>
								</tr>
							</tbody>
						</>
					)}
				</Table>
				{paginatedTable.length > 0 && (
					<PaninationBtn>
						<Button isDarkTheme={isDarkTheme} label="Previous" ifClicked={prevPage} disabled={selectedPage === 1} active={selectedPage !== 1} margin="0 .5rem 0 0" />
						<Button isDarkTheme={isDarkTheme} label="Next" ifClicked={nextPage} disabled={selectedPage === pagesItem} active={selectedPage !== pagesItem} />
					</PaninationBtn>
				)}
			</Div>
		</>
	)
}

const Div = styled.div`
	margin: 0.8rem 0 0rem 0;
	padding-bottom: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
`

const ListHeading = styled.td`
	font-weight: bold;
	font-size: 12.5px;
	position: relative;
`
const PaninationBtn = styled.div`
	display: flex;
	margin-top: 1rem;
`
const Td = styled.td`
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
	margin: 0;
	font-size: 12.5px;
	font-weight: bold;
	cursor: pointer;
`
const Icons = styled.img`
	width: 20.2px;
	height: 20.2px;
	border-radius: 3rem;
	margin: 0 0.2rem 0 0.2rem;
`
const Flex = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
`
const Uparrow = styled(TiArrowSortedDown)`
	font-size: 20px;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.charcol}`)};
	position: absolute;
	top: 7px;
`
const DownArrow = styled(TiArrowSortedUp)`
	font-size: 20px;
	position: absolute;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.charcol}`)};
	top: 7px;
`

export default PairsTableEthereum
