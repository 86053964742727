import React, {useContext} from 'react'
import Graph from '../components/Graph'
import GraphTable from '../components/GraphTable'
import styled from 'styled-components'
import {Container, Row} from 'styled-bootstrap-grid'
import AppContext from '../api/AppContext'

const GraphView = () => {
	const context = useContext(AppContext)
	const {showGraph} = context

	/*eslint-disable */
	history?.pushState(null, null, null)
	window.addEventListener('popstate', () => {
		history.pushState(null, null, null)
	})
	return (
		<Container>
			<Row justifyContent="center">
				<CustomCol>
					<Chart>
						{showGraph && (
							<>
								<Graph />
								<GraphTable />
							</>
						)}
					</Chart>
				</CustomCol>
			</Row>
		</Container>
	)
}

const CustomCol = styled.div`
	padding: 0px;
`
const Chart = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`
export default GraphView
