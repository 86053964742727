import React, {useEffect, useState, useContext} from 'react'
import styled from 'styled-components'
import {palette} from '../Colors'
import PairsTableEthereum from '../components/PairsTableEthereum'
import AppContext from '../api/AppContext'
import axios from 'axios'
import {uniswap, sushi, shibaSwap, nomiSwap} from '.././components/Constants'

const AllDexEthereum = ({setTrendingCoin}) => {
	const context = useContext(AppContext)
	const {isDarkTheme} = context
	const [dexData, setDexData] = useState(uniswap)
	const [limit, setLimit] = useState('1000')
	const [tableDataEth, setTableDataEth] = useState([])
	const [loading, setLoading] = useState(false)
	const [selectedPage, setSelectedPage] = useState(1)
	useEffect(() => {
		setTableDataEth([])
		setTrendingCoin('ethereum')
		pairDataApiEthereum()
	}, [dexData])

	const pairDataApiEthereum = async () => {
		setLoading(true)
		await axios
			.post(
				`${process.env.REACT_APP_BACKEND_URL}/pairs`,
				//const response = await axios.post('http://localhost:7272/chart/pairs',
				{
					dexData: dexData,
					blockChain: 'ethereum',
					limits: limit
				}
			)
			.then(async (response) => {
				if (response.status) {
					setTableDataEth(response.data.results)
				} else {
				}
				setLoading(false)
			})
			.catch(function (error) {
				setLoading(false)
			})
	}
	return (
		<>
			<Wrapper isDarkTheme={isDarkTheme}>
				<List>
					<Flex isDarkTheme={isDarkTheme}>
						<Text>AllDexes</Text>
					</Flex>
					<Flex
						isDarkTheme={isDarkTheme}
						active={dexData === uniswap}
						onClick={() => {
							setDexData(uniswap)
						}}>
						<Icons src="/images/uniswap.png" alt="logo" />
						<Text>Uniswap</Text>
					</Flex>
					<Flex
						isDarkTheme={isDarkTheme}
						active={dexData === sushi}
						onClick={() => {
							setDexData(sushi)
							setLimit('600')
							setSelectedPage(1)
						}}>
						<Icons src="/images/sushiswap.png" alt="logo" />
						<Text>SushiSwap</Text>
					</Flex>
					<Flex isDarkTheme={isDarkTheme}>
						<Icons src="/images/pancakeswap.png" alt="logo" />
						<Text>PancakeSwap</Text>
					</Flex>
					<Flex isDarkTheme={isDarkTheme}>
						<Icons src="/images/mdex.jpeg" alt="logo" />
						<Text>MDEX</Text>
					</Flex>
					<Flex
						isDarkTheme={isDarkTheme}
						active={dexData === shibaSwap}
						onClick={() => {
							setDexData(shibaSwap)
							setLimit('50')
							setSelectedPage(1)
						}}>
						<Icons src="/images/shibswap.png" alt="logo" />
						<Text>ShibaSwap</Text>
					</Flex>

					<Flex
						isDarkTheme={isDarkTheme}
						active={dexData === 'crypto-com-defi-swap'}
						onClick={() => {
							setDexData('crypto-com-defi-swap')
							setLimit('20')
							setSelectedPage(1)
						}}>
						<Icons src="/images/defiswap.jpeg" alt="logo" />
						<Text>DefiSwap</Text>
					</Flex>
					<Flex
						isDarkTheme={isDarkTheme}
						active={dexData === nomiSwap}
						onClick={() => {
							setDexData(nomiSwap)
						}}>
						<Icons src="/images/fraxswap.png" alt="logo" />
						<Text>FraxSwap</Text>
					</Flex>
					<Flex isDarkTheme={isDarkTheme}>
						<Icons src="/images/safemoonswap.jpeg" alt="logo" />
						<Text>SafeMoonSwap</Text>
					</Flex>
				</List>
			</Wrapper>
			<PairsTableEthereum tableDataEth={tableDataEth} loading={loading} setSelectedPage={setSelectedPage} selectedPage={selectedPage} />
		</>
	)
}

const Wrapper = styled.div`
	padding: 1rem;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.transparent_white}`)};
	box-shadow: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_mode_shadow}` : `${palette.light_mode_shadow}`)};
`

const Flex = styled.div`
	display: flex;
	cursor: pointer;
	color: ${({active, isDarkTheme}) => (active ? `${palette.aqua_blue}` : isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
	&:hover {
		color: ${palette.aqua_blue};
	}
`

const List = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	flex-wrap: wrap;
	gap: 1rem 2rem;
`

const Text = styled.p`
	font-size: 14px;
`

const Icons = styled.img`
	width: 20.2px;
	height: 20.2px;
	border-radius: 3rem;
	margin-right: 0.5rem;
`

export default AllDexEthereum
