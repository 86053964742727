import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { palette } from '../Colors'
import AppContext from '../api/AppContext'
import { AiOutlineClockCircle, AiOutlinePlus, AiOutlineMinus, AiOutlinePlusCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { TiTickOutline } from 'react-icons/ti'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import SearchModal from '../modal/SearchModal'
import DestroyChart from '../modal/DestroyChart'
import MultiGraph from '../components/MultiGraph'
import { Flexed } from '../styles/shared'

const MultiChart = () => {
	const context = useContext(AppContext)
	const { isDarkTheme } = context
	const [column, setColumn] = useState(3)
	const [heights, setHeights] = useState(100)
	const [open, setOpen] = useState(false)

	const [isCheckedConfirm, setIsCheckedConfirm] = useState(false)
	const [localItems, setLocalItems] = useState(JSON.parse(localStorage.getItem('chart')) || [])
	const [displayTime, setDisplayTime] = useState(false)
	const [textButton, setTextButton] = useState('15 minutes')
	const [timeInterVal, setTimeInterVal] = useState('1m')

	localStorage.setItem('chart', JSON.stringify(localItems))
	const [showModal, setShowModal] = useState({
		isVisible: false,
		userId: null
	})

	const addChart = (option) => {
		setLocalItems((prevItems) => [...prevItems, option])
		//setChart((current) => [...current, option])
	}

	//const chartStorageData = window.localStorage.setItem('MY_APP_STATE', JSON.stringify(chart))
	const showModalOnClick = (id) => {
		if (!isCheckedConfirm) {
			setShowModal({
				isVisible: true,
				userId: id
			})
			return
		}
		setShowModal({
			isVisible: false
		})
	}

	const deleteChart = () => {
		const deleteOne = localItems.filter((value, ind) => {
			return showModal.userId !== value.pair_id
		})
		setLocalItems(deleteOne)
		setShowModal({
			isVisible: false
		})
	}

	return (
		<>
			<List>
				<DropDownList>
					<Flex
						isDarkTheme={isDarkTheme}
						active={displayTime === true}
						onClick={() => {
							setDisplayTime(true)
						}}>
						<Icons />
						Chart Interval:{textButton}
					</Flex>
					<DropDownContent isDarkTheme={isDarkTheme} displayTime={displayTime}>
						{/* <Flexed direction="row" justify="center" align="center"> */}

						<DropDownTextDefault
							isDarkTheme={isDarkTheme}
							onClick={() => {
								setDisplayTime(false)
								setTextButton('1 minute')
								setTimeInterVal('1m')
							}}>
							<TiTickOutline />
							<span>Default 1 minute</span>

						</DropDownTextDefault>
						{/* </Flexed> */}
						<DropDownText
							isDarkTheme={isDarkTheme}
							onClick={() => {
								setDisplayTime(false)
								setTextButton('5 minutes')
								setTimeInterVal('5m')
							}}>
							5 minutes
						</DropDownText>
						<DropDownText
							isDarkTheme={isDarkTheme}
							onClick={() => {
								setDisplayTime(false)
								setTextButton('15 minutes')
								setTimeInterVal('15m')
							}}>
							15 minutes
						</DropDownText>
						<DropDownText
							isDarkTheme={isDarkTheme}
							onClick={() => {
								setDisplayTime(false)
								setTextButton('1 Hour ')
								setTimeInterVal('1h')
							}}>
							1 Hour
						</DropDownText>
						<DropDownText
							isDarkTheme={isDarkTheme}
							onClick={() => {
								setDisplayTime(false)
								setTextButton('4 Hour ')
								setTimeInterVal('4h')
							}}>
							4 Hour
						</DropDownText>
						<DropDownText
							isDarkTheme={isDarkTheme}
							onClick={() => {
								setTextButton('1 Day ')
								setTimeInterVal('1d')
								setDisplayTime(false)
							}}>
							1 Day
						</DropDownText>
					</DropDownContent>
				</DropDownList>

				<ButtonList>
					<Flex
						isDarkTheme={isDarkTheme}
						disabled={column <= 1}
						onClick={() => {
							setColumn(column - 1)
						}}>
						<AiOutlineMinus />
					</Flex>
					<Text isDarkTheme={isDarkTheme}>Columns: {column}</Text>
					<Flex
						isDarkTheme={isDarkTheme}
						disabled={column >= 3}
						onClick={() => {
							setColumn(column + 1)
						}}>
						<AiOutlinePlus />
					</Flex>
				</ButtonList>
				<ButtonList>
					<Flex
						isDarkTheme={isDarkTheme}
						onClick={() => {
							setHeights(heights - 10)
						}}>
						<AiOutlineMinus />
					</Flex>

					<Text isDarkTheme={isDarkTheme}>Height: {heights}%</Text>

					<Flex
						isDarkTheme={isDarkTheme}
						onClick={() => {
							setHeights(heights + 10)
						}}>
						<AiOutlinePlus />
					</Flex>
				</ButtonList>
			</List>
			<CustomContainer fluid>
				<Row>
					<CustomCol>
						{localItems.map((value, index) => {
							return (
								<SubList isDarkTheme={isDarkTheme} >
									<CustomClose isDarkTheme={isDarkTheme}
										onClick={() => {
											showModalOnClick(value.pair_id)
										}}
									/>
									<MultiGraph value={value} column={column} key={column} timeInterVal={timeInterVal} />
								</SubList>
							)
						})}
						<SubList column={column} heights={heights} isDarkTheme={isDarkTheme}>
							<OutLinePlus
								isDarkTheme={isDarkTheme}
								onClick={() => {
									setOpen(true)
								}}
							/>
							<AddBtn
								isDarkTheme={isDarkTheme}
								onClick={() => {
									setOpen(true)
								}}>
								Add
							</AddBtn>
						</SubList>
					</CustomCol>
				</Row>
			</CustomContainer>

			<DestroyChart showModal={showModal} deleteChart={deleteChart} setShowModal={setShowModal} />
			<SearchModal open={open} setOpen={setOpen} addChart={addChart} localItems={localItems} />
		</>
	)
}

const List = styled.div`
	display: flex;
	flex-wrap:wrap;
	justify-content: center;
	align-items: center;
	gap: 1rem;
`

const Flex = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&:hover {
		background: ${({ isDarkTheme }) => (isDarkTheme ? `rgb(255 255 255 / 38%);` : `${palette.gray}`)};
	}
	background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_dark}` : `${palette.white}`)};
	color: ${({ active, isDarkTheme }) => (active ? `${palette.aqua_blue}` : isDarkTheme ? `${palette.pure_white}` : `${palette.off_dark}`)};
	padding: 0.4rem 0.8rem;
	border-radius: 10px;
	border: none;
	font-size: 16px;
	font-weight: 500;
	gap: 5px;
	position: relative;
	min-height: 36.8px;
`
const DropDownList = styled.div`
	position: relative;
`
const DropDownContent = styled.div`
	display: ${({ displayTime, active }) => (displayTime ? 'block' : 'none')};
	min-width: 230px;
	position: absolute;
	background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_dark}` : `${palette.white}`)};
	color: ${({ active, isDarkTheme }) => (active ? `${palette.aqua_blue}` : isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
	min-width: 230px;
	top: 2.5rem;
	z-index: 999;
	border-radius: 10px;
	cursor: pointer;
`

const Icons = styled(AiOutlineClockCircle)`
	width: 13.2px;
	height: 13.2px;
	border-radius: 3rem;
`
const ButtonList = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
`
const Text = styled.p`
	color: ${({ active, isDarkTheme }) => (active ? `${palette.aqua_blue}` : isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
`

const DropDownTextDefault = styled.p`
	display: flex;
    align-items: center;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
	padding: 0.5rem;
	&:hover{
		background: ${({ isDarkTheme }) => (isDarkTheme ? `rgb(255 255 255 / 38%);` : `${palette.gray}`)};
	}
	&:span{
		padding-left: 0.5rem;
	}
`

const DropDownText = styled.p`
	display: flex;
    align-items: center;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
	padding: 0.5rem 2rem;
	&:hover{
		background: ${({ isDarkTheme }) => (isDarkTheme ? `rgb(255 255 255 / 38%);` : `${palette.gray}`)};
	}
`

const SubList = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_dark}` : `${palette.white}`)};
	flex-direction: column;
	min-height: ${({ column }) => (column === 1 ? '300px' : column === 2 ? '300px' : column === 3 ? '300px' : '')};
	min-width: ${({ column }) => (column === 1 ? '100%' : column === 2 ? '49.3%' : column === 3 ? '33%' : '')};
	flex-wrap: wrap;
	position: relative;
`
const OutLinePlus = styled(AiOutlinePlusCircle)`
	font-weight: 600;
	color: ${({ active, isDarkTheme }) => (active ? `${palette.aqua_blue}` : isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
	font-size: 3rem;
	cursor: pointer;
`
const AddBtn = styled.button`
	padding: 0.5rem 2rem;
	margin: 0.5rem 0.5rem;
	border-radius: 10px;
	background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_dark}` : `${palette.white}`)};
	color: ${({ active, isDarkTheme }) => (active ? `${palette.aqua_blue}` : isDarkTheme ? `${palette.pure_white}` : `${palette.off_dark}`)};
	&:hover {
		background: ${({ isDarkTheme }) => (isDarkTheme ? `rgb(255 255 255 / 38%);` : `${palette.gray}`)};
	}
	cursor: pointer;
	border: none;
`
const CustomCol = styled(Col)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5rem;
	flex-wrap: wrap;
`
const CustomClose = styled(AiOutlineCloseCircle)`
	position: absolute;
	top: 0.2rem;
	right: 0.2rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
`
const CustomContainer = styled(Container)`
	padding: 0;
	margin-top: 2rem;
`
export default MultiChart
