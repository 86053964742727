import React, {useEffect, useState, useContext, useRef} from 'react'
import Table from 'react-bootstrap/Table'
import styled from 'styled-components'
import {palette} from '../Colors'
import AppContext from '../api/AppContext'
import moment from 'moment'
import {ethers} from 'ethers'
import cryptoCompare from 'cryptocompare'
import {ERC20} from './ERC20'
import {USDT_contractAddress, USDC_contractAddress, USDT_eth_contractAddress, BUSD_contractAddress} from './Constants'

const GraphTable = () => {
	const myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/json')

	const context = useContext(AppContext)
	const {isDarkTheme, requiredPair} = context

	const [tableData, setTableData] = useState([])

	let isExit = false
	let blockNumber = []
	let transactionsRecord = []
	let requiredRecipt = []
	let transactions = ''
	let currentBlock = useRef(0)
	let bnbValue = useRef(0)
	let ETH_Value = 0

	const address = requiredPair.base_token_address
	const URL_ETH = `${process.env.REACT_APP_INFURA}`
	const URL_BSC = `${process.env.REACT_APP_BSC_DATASEED_URL}`

	//	https://mainnet.infura.io/v3/a57494b233774cf5965fa82db1c1ae10
	//	https://mainnet.infura.io/v3/9bb1c2fe5fe04e4cad23d28a2fd45bc5
	//	https://mainnet.infura.io/v3/d8831f096f8b41129990e9f12dfbc562
	//  https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161

	useEffect(() => {
		blockNumber = []
		getWBNB()
		let ethInterval = null
		let bnbInterval = null
		if (requiredPair.chain_slug === 'ethereum') {
			ethInterval = setInterval(() => {
				getBlockNumber()
			}, 12000)
		} else {
			bnbInterval = setInterval(() => {
				getBlockNumber()
			}, 2000)
		}
		return function cleanup() {
			isExit = true
			clearInterval(bnbInterval)
			clearInterval(ethInterval)
		}
	}, [requiredPair, isExit])

	const getWBNB = async () => {
		cryptoCompare
			.price(`BNB`, `USD`)
			.then((prices) => {
				bnbValue.current = prices.USD
				console.log('WBNB PRICE FROM API : ', bnbValue.current)
			})
			.catch(console.error)

		cryptoCompare
			.price(`eth`, `USD`)
			.then((prices) => {
				ETH_Value = prices.USD
				console.log('ETH PRICE FROM API : ', ETH_Value)
			})
			.catch(console.error)
	}

	const getBlockNumber = async () => {
		try {
			const raw = JSON.stringify({
				jsonrpc: '2.0',
				method: 'eth_blockNumber',
				params: [],
				id: 83
			})
			const requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			}

			const res = await fetchData(requestOptions)
			const response = await res.json()

			let blockIsAvailable = blockNumber.includes(response.result)

			if (blockNumber.length === 0) {
				blockNumber.push(response.result)
				// console.log('GETTING FIRST BLOCK')

				// blockNumber.push('0x17E6D75')

				getBlockByNumber()
			} else if (!blockIsAvailable) {
				blockNumber.push(response.result)
			}
		} catch (err) {
			console.log('err', err)
		}
	}

	const getBlockByNumber = async () => {
		const raw = JSON.stringify({
			jsonrpc: '2.0',
			method: 'eth_getBlockByNumber',
			params: [blockNumber[currentBlock.current], false],
			id: 1
		})
		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		}

		transactions = ''
		transactionsRecord = []

		console.log(blockNumber, 'blockNumber')
		console.log(`blockNumber${[currentBlock.current]}`, blockNumber[currentBlock.current])
		if (!isExit) {
			const res = await fetchData(requestOptions)
			const response = await res.json()

			currentBlock.current = currentBlock.current + 1

			let data = response
			transactions = data?.result?.transactions

			getTransactionReceipt()
		}
	}

	const getTransactionReceipt = async () => {
		for (let transactionNumber = 0; transactionNumber < transactions?.length - 1; transactionNumber++) {
			if (isExit) {
				break
			}
			const requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: JSON.stringify({
					jsonrpc: '2.0',
					method: 'eth_getTransactionReceipt',
					params: [transactions[transactionNumber]],
					id: 1
				}),
				redirect: 'follow'
			}
			const res = await fetchData(requestOptions)
			const response = await res.json()
			transactionsRecord.push(response)
		}
		getRequireRecipt()
	}

	const getRequireRecipt = () => {
		requiredRecipt = []
		for (let transactionNumber = 0; transactionNumber < transactionsRecord?.length - 1; transactionNumber++) {
			for (let logNumber = 0; logNumber < transactionsRecord[transactionNumber]?.result?.logs?.length - 1; logNumber++) {
				if (transactionsRecord[transactionNumber].result.logs[logNumber]?.address === address?.toLowerCase() || transactionsRecord[transactionNumber]?.result?.logs[logNumber]?.address === address?.toUpperCase()) {
					if (requiredRecipt?.length === 0) {
						requiredRecipt.push(transactionsRecord[transactionNumber])
					} else {
						for (let number = 0; number < requiredRecipt.length - 1; number++) {
							if (requiredRecipt[number]?.result?.transactionHash !== transactionsRecord[transactionNumber]?.result?.transactionHash) {
								requiredRecipt.push(transactionsRecord[transactionNumber])
							}
						}
					}
				}
			}
		}

		if (requiredRecipt.length !== 0) {
			findValues()
		} else {
			getBlockByNumber()
		}
	}

	const findValues = async () => {
		const extraZeros = '0x000000000000000000000000'
		const poolAddress = extraZeros.concat(requiredPair.pool_address.slice(2))

		let buyFirstValue = []
		let sellFirstValue = ''
		let buySecondValue = ''
		let sellSecondValue = ''
		let tokenDetails = ''

		let count = 0
		let counter = 0

		if (requiredPair.chain_slug === 'ethereum') {
			const provider = new ethers.providers.JsonRpcProvider(`${process.env.REACT_APP_INFURA}`)
			tokenDetails = await fetchTokenDetails(requiredPair.base_token_address, provider)
		}

		requiredRecipt[0]?.result.logs.map((recipt) => {
			if (recipt.address === address) {
				if (recipt.topics[2]?.toLowerCase() === poolAddress?.toLowerCase() || recipt.topics[2]?.toLowerCase() === requiredPair.pool_address) {
					sellFirstValue = recipt.data.slice(2)
					if (requiredPair.chain_slug === 'ethereum') {
						sellFirstValue = parseInt(sellFirstValue, 16) / 10 ** tokenDetails.decimals
					} else {
						sellFirstValue = parseInt(sellFirstValue, 16) / 10 ** 18
					}

					console.log('sellFirstValue value 1', sellFirstValue)
				}
			} else if (recipt.address?.toLowerCase() === requiredPair?.quote_token_address?.toLowerCase()) {
				if (recipt.topics[1]?.toLowerCase() === poolAddress?.toLowerCase() || recipt.topics[1]?.toLowerCase() === requiredPair.pool_address) {
					sellSecondValue = recipt.data.slice(2)

					if (requiredPair.quote_token_address === USDC_contractAddress || requiredPair.quote_token_address === USDT_eth_contractAddress) {
						sellSecondValue = parseInt(sellSecondValue, 16) / 10 ** 6
					} else {
						sellSecondValue = parseInt(sellSecondValue, 16) / 10 ** 18
					}

					console.log('sellSecondValue value 2', sellSecondValue)

					if (requiredPair.quote_token_address === USDT_contractAddress || requiredPair.quote_token_address === BUSD_contractAddress) {
						if (sellFirstValue || sellSecondValue) {
							let temp = tableData
							temp.unshift({
								date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
								type: 'Sell',
								token: sellFirstValue,
								baseToken: sellSecondValue,
								price: sellSecondValue / sellFirstValue,
								txn: requiredRecipt[0]?.result?.transactionHash
							})
							setTableData(temp)
						}
					} else if (requiredPair.quote_token_address === USDC_contractAddress || requiredPair.quote_token_address === USDT_eth_contractAddress) {
						let temp = tableData
						temp.unshift({
							date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
							type: 'Sell',
							token: sellFirstValue,
							baseToken: sellSecondValue,
							price: sellSecondValue / sellFirstValue,
							totalUsd: sellSecondValue,
							txn: requiredRecipt[0]?.result?.transactionHash
						})
						setTableData(temp)
					} else {
						let coinPrice = 0
						let totalUsd = 0

						if (requiredPair.chain_slug !== 'ethereum') {
							totalUsd = bnbValue.current * sellSecondValue
							coinPrice = totalUsd / sellFirstValue
						} else {
							totalUsd = ETH_Value * sellSecondValue
							coinPrice = totalUsd / sellFirstValue
						}

						if (sellFirstValue || sellSecondValue) {
							let temp = tableData
							temp.unshift({
								date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
								type: 'Sell',
								token: sellFirstValue,
								baseToken: sellSecondValue,
								price: coinPrice,
								totalUsd: totalUsd,
								txn: requiredRecipt[0]?.result?.transactionHash
							})

							setTableData(temp)
						}
					}
				} else if (recipt.topics[2]?.toLowerCase() === poolAddress?.toLowerCase() || recipt.topics[2]?.toLowerCase() === requiredPair.pool_address) {
					buySecondValue = recipt.data.slice(2)

					if (requiredPair.quote_token_address === USDC_contractAddress || requiredPair.quote_token_address === USDT_eth_contractAddress) {
						buySecondValue = parseInt(buySecondValue, 16) / 10 ** 6
					} else {
						buySecondValue = parseInt(buySecondValue, 16) / 10 ** 18
					}

					console.log('buySecondValue value 2', buySecondValue)

					requiredRecipt[0]?.result.logs.map((recipt) => {
						if (recipt.address === address) {
							if (recipt.topics[1]?.toLowerCase() === poolAddress?.toLowerCase() || recipt.topics[1]?.toLowerCase() === requiredPair.pool_address) {
								if (requiredPair.chain_slug === 'ethereum') {
									buyFirstValue[count] = parseInt(recipt.data.slice(2), 16) / 10 ** tokenDetails.decimals
								} else {
									buyFirstValue[count] = parseInt(recipt.data.slice(2), 16) / 10 ** 18
								}

								count = count + 1

								console.log('buyFirstValue value 1', buyFirstValue)
							}
						}
					})
					if (requiredPair.quote_token_address === USDT_contractAddress || requiredPair.quote_token_address === BUSD_contractAddress) {
						if (buyFirstValue !== '' || buySecondValue) {
							let temp = tableData

							temp.unshift({
								date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
								type: 'Buy',
								token: buyFirstValue[counter],
								baseToken: buySecondValue,
								price: buySecondValue / buyFirstValue[counter],
								txn: requiredRecipt[0]?.result?.transactionHash
							})
							counter = counter + 1
							setTableData(temp)
						}
					} else if (requiredPair.quote_token_address === USDC_contractAddress || requiredPair.quote_token_address === USDT_eth_contractAddress) {
						let temp = tableData

						temp.unshift({
							date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
							type: 'Buy',
							token: buyFirstValue[counter],
							baseToken: buySecondValue,
							price: buySecondValue / buyFirstValue[counter],
							totalUsd: buySecondValue,
							txn: requiredRecipt[0]?.result?.transactionHash
						})
						setTableData(temp)
					} else {
						let coinPrice = 0
						let totalUsd = 0

						if (requiredPair.chain_slug !== 'ethereum') {
							totalUsd = bnbValue.current * buySecondValue
							coinPrice = totalUsd / buyFirstValue[counter]
						} else {
							totalUsd = ETH_Value * buySecondValue
							coinPrice = totalUsd / buyFirstValue[counter]
						}

						if (buyFirstValue !== '' || buySecondValue) {
							let temp = tableData
							temp.unshift({
								date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
								type: 'Buy',
								token: buyFirstValue[counter],
								baseToken: buySecondValue,
								price: coinPrice,
								totalUsd: totalUsd,
								txn: requiredRecipt[0]?.result?.transactionHash
							})
							counter = counter + 1

							setTableData(temp)
						}
					}
				}
			}
		})

		getBlockByNumber()
	}

	const fetchTokenDetails = async (tokenAddress, provider) => {
		try {
			const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, provider)
			let tokenDetails = {}
			tokenDetails.name = await erc20.name()
			tokenDetails.symbol = await erc20.symbol()
			tokenDetails.decimals = await erc20.decimals()

			return tokenDetails
		} catch (error) {
			return null
		}
	}

	const fetchData = async (requestOptions) => {
		let res = ''
		if (requiredPair.chain_slug === 'ethereum') {
			res = await fetch(`${URL_ETH}`, requestOptions)
		} else {
			res = await fetch(`${URL_BSC}`, requestOptions)
		}
		return res
	}

	return (
		<Div>
			<Table striped bordered variant="dark">
				<thead>
					<tr>
						<Th isDarkTheme={isDarkTheme}>DATE</Th>
						<Th isDarkTheme={isDarkTheme}>TYPE</Th>
						<Th isDarkTheme={isDarkTheme}>{requiredPair.base_token_symbol}</Th>
						<Th isDarkTheme={isDarkTheme}>{requiredPair.quote_token_symbol}</Th>
						<Th isDarkTheme={isDarkTheme}>PRICE</Th>
						<Th isDarkTheme={isDarkTheme}>TXNS</Th>
					</tr>
				</thead>

				<tbody>
					{tableData.map((row, index) => {
						return (
							<tr key={index}>
								<Td color={`${isDarkTheme ? palette.charcol : palette.off_dark}`}>{row.date}</Td>
								<Td active={row.type === 'Buy'}>{row.type}</Td>
								<Td active={row.type === 'Buy'}>{parseFloat(row.token).toLocaleString()}</Td>
								<Td active={row.type === 'Buy'}>
									{requiredPair.chain_slug !== 'ethereum' ? (
										row.totalUsd ? (
											<>
												<div>{`$${row?.totalUsd}`}</div>
												<div>{`${parseFloat(row.baseToken).toFixed(5)} ${requiredPair.quote_token_symbol}`}</div>
											</>
										) : (
											`$${parseFloat(row.baseToken).toFixed(5)} ${requiredPair.quote_token_symbol}`
										)
									) : (
										<>
											<div>{`$${row?.totalUsd}`}</div>
											<div>{`${parseFloat(row.baseToken).toFixed(5)} ${requiredPair.quote_token_symbol}`}</div>
										</>
									)}
								</Td>
								<Td active={row.type === 'Buy'}>{`$${row.price} `}</Td>
								<Td color={palette.aqua_blue}>
									<FLEX>
										<Link target="_blank" href={requiredPair.chain_slug !== 'ethereum' ? `https://bscscan.com/tx/${row.txn}` : `https://etherscan.io/tx/${row.txn}`} onClick={() => console.log('inner console', row.txn)}>
											{` ${row.txn.substr(0, 4)}...${row.txn.substr(62)}`}
											<Icon src={requiredPair.chain_slug !== 'ethereum' ? '/images/bscScan.webp' : '/images/etherscan-logo-circle.png'} />
										</Link>
									</FLEX>
								</Td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		</Div>
	)
}

const Div = styled.div`
	margin-top: 2rem;
`
const Link = styled.a`
	all: unset;
	cursor: pointer;
	color: ${palette.aqua_blue};
	background-color: transparent;
	text-decoration: none;

	&:hover {
		color: ${palette.aqua_blue};
	}
`

const Th = styled.th`
	color: ${({isDarkTheme}) => (isDarkTheme ? ` ${palette.white}` : ` ${palette.off_dark}`)};
	font-size: 12px;
`

const Td = styled.td`
	color: ${({color, active}) => (color ? color : active ? `${palette.table_green}` : `${palette.red}`)}!important;
	font-size: 13.5px;
`

const Icon = styled.img`
	width: 14px;
	margin-left: 10px;
	padding: 1px;
	border-radius: 50%;
	margin-left: ${({marginLeft}) => marginLeft};
	height: 14px;
	background-color: ${palette.pure_white};
`
const FLEX = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
export default GraphTable
