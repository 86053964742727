import React, { useContext } from 'react'
import styled from 'styled-components'
import AppContext from '../api/AppContext'
import Button from '../components/Button'
//import Modal from 'react-bootstrap/Modal'
import { Modal } from 'react-responsive-modal'
import { Flexed } from '../styles/shared'
import { AiOutlineClose } from 'react-icons/ai'
import { palette } from '../Colors'

function DestroyChart({ showModal, setShowModal, deleteChart }) {
	const onCloseModal = () => setShowModal({ isVisible: false })
	const context = useContext(AppContext)
	const { isDarkTheme } = context

	return (
		<>
			<Modal
				open={showModal.isVisible}
				onClose={() => {
					onCloseModal()
				}}
				center
				isDarkTheme={isDarkTheme}
				classNames={{
					overlay: 'customOverlay',
					modal: `${isDarkTheme ? 'customModalNew' : 'customModalNewLight'}`,
					closeButton: `${isDarkTheme ? 'closeModel' : ''}`
				}}>
				<Flexed direction="row" justify="space-between" align="center">
					<Text isDarkTheme={isDarkTheme}>Destroy chart</Text>
				</Flexed>
				<Flexed margin="2rem 0rem 2rem 0rem">
					<TextNew isDarkTheme={isDarkTheme}>Are you sure you want to delete chart</TextNew>
				</Flexed>
				<Flexed direction="row" justify="space-between" align="center">
					<Button
						type="blue"
						isDarkTheme={isDarkTheme}
						label="No"
						ifClicked={() => {
							onCloseModal()
						}}
					/>
					<Button
						type="red"
						isDarkTheme={isDarkTheme}
						label="Yes"
						ifClicked={() => {
							deleteChart();
						}}
					/>
				</Flexed>

			</Modal>
		</>
	)
}
const Text = styled.p`
	font- size: 1rem;
			color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
			font-weight: 600;
			`
const CloseIcon = styled(AiOutlineClose)`
			color: ${palette.white};
			cursor: pointer;
			`
const TextNew = styled.p`
			color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
			font-size: 1rem;
			`
const CustomButton = styled(Button)`
			margin-left: 1rem;
			background-color: ${palette.light_pink};
			border: none;
			`

export default DestroyChart
