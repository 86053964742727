import { palette } from "../Colors";
import styled from 'styled-components'

export const Heading = styled.h1`
    font-size: 1.25rem;
    margin: ${({ margin }) => (margin ? `${margin}` : '0')};
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.heading}` : `${palette.off_dark}`)};
    text-align: ${({ isCentered }) => (isCentered ? `center` : 'inherit')};
    word-break: break-all;
`

export const Text = styled.p`
    // line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '2.16rem')};
    line-height: ${({ small, medium, xsmall }) => (small ? '1.54rem' : medium ? '1.85rem' : xsmall ? '1.16rem' : '2.16rem')};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    font-size: ${({ xsmall, small, medium }) => (small ? '1rem' : medium ? '1.231rem' : xsmall ? '0.875rem' : '1.39rem')};
    font-family: ${({ font }) => font};
    // color: ${({ color }) => palette[color]};
    color: ${({ color, isDarkTheme }) => (color ? palette[color] : isDarkTheme ? palette.off_white : palette.off_dark)};
    margin: ${({ margin }) => `${margin}`};
    text-align: ${({ isCentered }) => (isCentered ? `center` : 'inherit')};
    cursor: ${({ pointer }) => (pointer ? `pointer` : 'inherit')};
`

export const NotFoundText = styled.h6`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.charcol}`)};
	height:5rem;
	display:flex;
	justify-content:center;
	align-items:center;
`;

export const TableLoading = styled.div`
	color:${palette.white};
	height:5rem;
	display:flex;
	justify-content:center;
	align-items:center;
	position: relative;
`;

export const TableLoadingPanelContent = styled.div`
	width: 100%;
	position: absolute;
	z-index: 3;
	top: 0;
	right: 0;
	left: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.overlay}` : `${palette.white}`)};
`

export const TableLoadingSpiner = styled.img`
	width: 15%;
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	left: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	filter: ${({ isDarkTheme }) => (isDarkTheme ? `invert(61%) sepia(26%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)` : `invert(61%) sepia(2%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)`)};
`

export const Flexed = styled.div`
    display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
    flex-direction: column;
    flex-direction: ${({ direction }) => direction};
    align-items: ${({ align }) => align};
    justify-content: ${({ justify }) => justify};
    margin: ${({ margin }) => `${margin}`};
`
