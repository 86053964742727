import React, {useContext, useEffect, useState} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {Container, media} from 'styled-bootstrap-grid'
import AppContext from './api/AppContext'
import styled from 'styled-components'
import NavBar from './components/NavBar'
import Sidebar from './components/Sidebar'
import SubNavBar from './components/SubNavBar'
import GraphView from './pages/GraphView'
import AllDexBinance from './pages/AllDexBinance'
import AllDexEthereum from './pages/AllDexEthereum'
import Footer from './components/Footer'
import {Route, Routes} from 'react-router-dom'
import Wallet from './pages/Wallet'
import NewPairs from './pages/NewPairs'
import MultiChart from './pages/MultiChart'
import {palette} from './Colors'

const App = () => {
	const context = useContext(AppContext)
	const {isDarkTheme} = context
	/*eslint-disable */
	history?.pushState(null, null, null)
	window.addEventListener('popstate', () => {
		history.pushState(null, null, null)
	})
	const [trendingCoin, setTrendingCoin] = useState('All coin')

	useEffect(() => {
		if (isDarkTheme) {
			document.body.style.backgroundColor = `${palette.pie_chart_bg}`
		} else {
			document.body.style.backgroundColor = `${palette.pure_white}`
		}
	}, [isDarkTheme])

	return (
		<>
			<NavBar setTrendingCoin={setTrendingCoin} />
			<Main>
				<SubNavBar trendingCoin={trendingCoin} setTrendingCoin={setTrendingCoin} />
				<Flex>
					<Sidebar setTrendingCoin={setTrendingCoin} trendingCoin={trendingCoin} />
					<Wrapper fluid>
						<Routes>
							{/* <Route path="/" element={<GraphView />}></Route> */}
							<Route path="/" element={<AllDexBinance trendingCoin={trendingCoin} setTrendingCoin={setTrendingCoin} />}></Route>
							<Route path="/binance/*" element={<GraphView />}></Route>
							<Route path="/ethereum" element={<AllDexEthereum trendingCoin={trendingCoin} setTrendingCoin={setTrendingCoin} />}></Route>
							<Route path="/ethereum/*" element={<GraphView />}></Route>
							<Route path="/wallet" element={<Wallet />}></Route>
							<Route path="/new-pairs" element={<NewPairs />}></Route>
							<Route path="/multi-charts" element={<MultiChart />}></Route>
						</Routes>
						<Footer />
					</Wrapper>
				</Flex>
			</Main>
		</>
	)
}

const Main = styled.div`
	padding-top: 5rem;
	${media.xs`padding-top: 8rem;`}
`

const Flex = styled.div`
	display: flex;
	padding: 4rem 1rem 0rem 1rem;
	${media.xs`
	padding: 4rem 0rem 0rem 0rem;
	`};
`

const Wrapper = styled(Container)`
	overflow: auto;
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 9rem);
`
export default App
